import produce from 'immer';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { fromListToLookup, ListAndLookup } from '../helpers/lookup';
import { ServerDevice } from '../models/Device';
import { muiTheme } from '../theme/SenseViewTheme';

interface DeviceStore extends ListAndLookup<ServerDevice> {
  tags: string[];
  tagColors: Record<string, string>;
  load: (devices: ServerDevice[]) => void;
  setDevice: (device: ServerDevice) => void;
  removeDevice: (device: ServerDevice['id']) => void;
}

const recalculateTags = (devices: ServerDevice[]) => {
  const tags: string[] = [
    ...new Set(
      devices.reduce<string[]>((acc, device) => {
        device.meta.tags.forEach((tag) => {
          acc.push(tag);
        });
        return acc;
      }, []),
    ),
  ].sort((a, b) => a.localeCompare(b));
  const tagColors = tags.reduce<Record<string, string>>((acc, tag, i) => {
    return {
      ...acc,
      [tag]: muiTheme.palette.tags[i % muiTheme.palette.tags.length],
    };
  }, {});

  return { tags, tagColors };
};

export const useDevices = create<DeviceStore>(
  devtools(
    (set) => ({
      list: [],
      lookup: {},
      tags: [],
      tagColors: {},
      load: (devices) =>
        set(
          produce((state) => {
            const { tags, tagColors } = recalculateTags(devices);

            state.list = devices;
            state.lookup = fromListToLookup(devices);
            state.tags = tags;
            state.tagColors = tagColors;
          }),
        ),
      setDevice: (device) =>
        set(
          produce((state: DeviceStore) => {
            if (!state.lookup[device.id]) {
              state.list.push(device);
              state.lookup[device.id] = device;
              const { tags, tagColors } = recalculateTags(state.list);
              state.tags = tags;
              state.tagColors = tagColors;
              return;
            }

            // Find the device and ensure it is updated before updating state
            const i = state.list.findIndex(
              (d) => d.id === device.id && d.updated_at < device.updated_at,
            );
            if (i === -1) {
              return;
            }

            state.list[i] = device;
            state.lookup[device.id] = device;
            const { tags, tagColors } = recalculateTags(state.list);
            state.tags = tags;
            state.tagColors = tagColors;
          }),
        ),
      removeDevice: (id) => {
        set(
          produce((state: DeviceStore) => {
            if (!state.lookup[id]) {
              return;
            }

            const i = state.list.findIndex((d) => d.id === id);
            if (i === -1) {
              return;
            }

            state.list.splice(i, 1);
            delete state.lookup[id];
            const { tags, tagColors } = recalculateTags(state.list);
            state.tags = tags;
            state.tagColors = tagColors;
          }),
        );
      },
    }),
    { name: 'devices' },
  ),
);
