import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

export interface LocalePickerProps {
  locale: string;
  onLocale: (locale: string) => void;
}

const locales = [
  ['en-US', 'English'],
  ['sv-SE', 'Svenska'],
];

const LocalePicker: FC<LocalePickerProps> = ({ locale, onLocale }) => {
  return (
    <Stack spacing={1}>
      <Typography variant="h6">
        <FormattedMessage
          id="locale_picker.language"
          defaultMessage="Language"
        />
      </Typography>
      <ToggleButtonGroup
        exclusive
        value={locale}
        onChange={(event, value) => {
          if (value) {
            onLocale(value);
          }
        }}
      >
        {locales.map(([code, name]) => (
          <ToggleButton key={code} value={code} sx={{ textTransform: 'none' }}>
            {name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
};

export default LocalePicker;
