import produce from 'immer';
import { useCallback } from 'react';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { fromListToLookup, ListAndLookup } from '../helpers/lookup';
import { DeviceModel } from '../models/Device';
import { useDevices } from './devices';

interface ModelsStore extends ListAndLookup<DeviceModel> {
  load: (models: DeviceModel[]) => void;
}

export const useModels = create<ModelsStore>(
  devtools(
    (set) => ({
      list: [],
      lookup: {},
      load: (models) =>
        set(
          produce((state) => {
            state.list = models;
            state.lookup = fromListToLookup(models);
          }),
        ),
    }),
    { name: 'models' },
  ),
);

export const useDeviceModel = (deviceId: string): DeviceModel | undefined => {
  const device = useDevices(
    useCallback(({ lookup }) => lookup[deviceId], [deviceId]),
  );

  const model = useModels(
    useCallback(({ lookup }) => lookup[device?.model_id], [device?.model_id]),
  );

  return model;
};
