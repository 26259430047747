import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { FunctionComponent, useMemo } from 'react';

import { getFontColor } from '../../helpers/colors';
import { useDataDisplay } from '../../hooks/useDataDisplay';
import SensorValue from '../SensorValue';

interface WidgetContainerProps {
  primary?: boolean;
  icon: any;
  name: string;
  deviceId: string;
  sensorId: string;
  raised?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const Widget: FunctionComponent<WidgetContainerProps> = ({
  icon,
  deviceId,
  sensorId,
  name,
  primary,
  onClick,
}: WidgetContainerProps) => {
  const { deviceColors } = useDataDisplay();
  const color = useMemo(() => {
    if (deviceColors[deviceId]) {
      return deviceColors[deviceId][sensorId];
    }
    return undefined;
  }, [deviceColors, deviceId, sensorId]);

  return (
    <Tooltip title={name}>
      <ButtonBase
        data-testid="widget"
        onClick={onClick}
        sx={{
          display: 'block',
          m: primary ? 0 : 1,
          mb: primary ? 1 : 2,
          borderRadius: '50%',
          boxSizing: 'content-box',
          '&:hover': {
            textDecoration: 'none',
          },
        }}
      >
        <Card
          data-testid="card"
          raised={true}
          elevation={primary ? 6 : 3}
          sx={[
            {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              backgroundColor: color || '#ffffff',
              color: color ? getFontColor(color) : '#000000',
              width: 140,
              height: 140,
              cursor: onClick ? 'pointer' : 'default',
              transition: ({ transitions }) =>
                transitions.create('box-shadow', {
                  easing: transitions.easing.easeInOut,
                  duration: transitions.duration.shorter,
                }),
              '&:hover': {
                boxShadow: ({ shadows }) => shadows[10],
              },
            },
            primary
              ? {
                  borderWidth: 8,
                  borderStyle: 'solid',
                  borderColor: 'rgba(0,0,0,0.4)',
                }
              : false,
          ]}
        >
          <CardContent>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignContent="center"
              alignItems="center"
            >
              <Grid item xs alignItems="center" justifyContent="center">
                <Box
                  sx={{
                    margin: '0 auto 10px',
                    width: 40,
                    height: 40,
                    backgroundImage: `url(${icon})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                  }}
                  data-testid="widget-icon"
                />
              </Grid>
              <Grid item xs>
                <Typography
                  variant="h6"
                  align="center"
                  data-testid="widget-value"
                >
                  <SensorValue deviceId={deviceId} sensorId={sensorId} />
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography
                  variant="caption"
                  align="center"
                  display="block"
                  sx={{
                    maxWidth: '80px',
                    maxHeight: '40px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                  data-testid="widget-name"
                >
                  {name}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </ButtonBase>
    </Tooltip>
  );
};

export default Widget;
