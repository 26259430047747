import produce from 'immer';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface TransformsStore {
  transforms: Transform[];
  lookup: Record<string, Transform>;
  load: (transforms: Transform[]) => void;
}

export const useTransforms = create<TransformsStore>(
  devtools(
    (set) => ({
      transforms: [],
      lookup: {},
      load: (transforms) =>
        set(
          produce((state) => {
            state.transforms = transforms;
            state.lookup = transforms.reduce<Record<string, Transform>>(
              (acc, t) => {
                acc[`${t.id}`] = t;
                return acc;
              },
              {},
            );
          }),
        ),
    }),
    { name: 'transforms' },
  ),
);
