// import { setConfig } from '@allbin/error-reporting';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

export interface Config {
  api: {
    sso: {
      api_uri: string;
      login_uri: string;
    };
    senseview: {
      api_uri: string;
    };
    image: {
      api_uri: string;
    };
  };
  slack: {
    webhook_uri: string;
  };
  service_name: string;
  send_errors_to_slack_in_dev: boolean;
  default_language: 'sv-SE' | 'en-US';
  default_map_position: [number, number];
  default_map_zoom: number;
  map_access_token: string;
  sgroup_maps: boolean;
  widget_types_by_sensor_type: {
    [key: string]: string[];
  };
  update_check_timer: number;
}

export const defaultConfig: Config = {
  api: {
    sso: {
      api_uri: 'https://sso.allbin.se/v2',
      login_uri: 'https://login.allbin.se/v2',
    },
    senseview: {
      api_uri: 'https://api.testing.ioe.allbin.se',
    },
    image: {
      api_uri:
        'https://europe-west1-allbinary-204807.cloudfunctions.net/image_api',
    },
  },
  slack: {
    webhook_uri:
      'https://hooks.slack.com/services/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',
  },
  service_name: 'senseview_staging',

  send_errors_to_slack_in_dev: false,

  default_map_position: [15.5943379, 56.182023],
  default_map_zoom: 10,
  map_access_token:
    'pk.eyJ1IjoibWRqYXJ2IiwiYSI6ImNqcGpnenlhODAwOGcza28wNjZ4eWliYjEifQ.zhKhOCWFv50DK4pjHEcvXg',

  sgroup_maps: false,

  default_language: 'sv-SE',

  widget_types_by_sensor_type: {
    temperature: ['line-chart', 'number'],
    boolean: ['boolean', 'number'],
    pressure: ['line-chart', 'number'],
  },

  update_check_timer: 600000, // ms
};

interface ConfigState extends Config {
  load: (config: Config) => void;
  isLoaded: boolean;
}

// Merge props from right into left
function mergeLeft(left: any, right: any): any {
  return Object.keys(left).reduce((acc, key) => {
    if (right[key] !== undefined) {
      if (
        typeof right[key] !== 'object' ||
        right[key] === null ||
        right[key] instanceof Array
      ) {
        if (left[key] === right[key]) {
          return acc;
        }
        return { ...acc, [key]: right[key] };
      }

      return { ...acc, [key]: mergeLeft(left[key], right[key]) };
    }
    return acc;
  }, left);
}

export const useConfig = create<ConfigState>(
  devtools(
    (set) => ({
      ...defaultConfig,
      isLoaded: false,
      load: (config: Config) => {
        set((state) => mergeLeft(state, { ...config, isLoaded: true }));
      },
    }),
    { name: 'config' },
  ),
);
