import RuleIcon from '@mui/icons-material/DeveloperBoard';
import FlagIcon from '@mui/icons-material/Flag';
import GroupIcon from '@mui/icons-material/Group';
import MapIcon from '@mui/icons-material/Map';
import { Box, LinearProgress, Snackbar, SnackbarContent } from '@mui/material';
import { DateTime } from 'luxon';
import React, {
  FC,
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, useLocation, useNavigate } from 'react-router-dom';

import AllbinAppBar from '../components/AppBar/AllbinAppBar';
import ReloadWindowDialog from '../components/ReloadWindowDialog';
import { useConfig } from '../config';
import update_check from '../helpers/update_check';
import SentryRoutes from '../SentryRoutes';
import { useConnection } from '../store/connected';
import { useSSOStore } from '../store/sso';

const ProjectsView = lazy(() => import('./ProjectsView'));
const RulesView = lazy(() => import('./RulesView'));
const MapView = lazy(() => import('./MapView'));
const GroupsView = lazy(() => import('./GroupsView'));

interface MainViewProps {
  locale: string;
  setLocale: (locale: string) => void;
}

const MainView: FC<MainViewProps> = (props: MainViewProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const config = useConfig(
    useCallback(
      (state) => ({
        updateCheckTimer: state.update_check_timer,
        sgroupMaps: state.sgroup_maps,
        defaultMapPosition: state.default_map_position,
        defaultMapZoom: state.default_map_zoom,
        mapAccessToken: state.map_access_token,
        serviceName: state.service_name,
      }),
      [],
    ),
  );

  const connected = useConnection(useCallback((state) => state.connected, []));

  const token = useSSOStore(useCallback(({ token }) => token, []));

  // const currentUser = useMemo(
  //   () => ({
  //     uid: token?.payload.uid?.toString() || '-1', //users.my_id.toString(),
  //     admin: token?.isAdmin() || false,
  //   }),
  //   [token],
  // );

  const [timeToReload, setTimeToReload] = useState<DateTime | undefined>(
    undefined,
  );

  const handleWindowReload = useCallback(() => {
    window.location.reload();
  }, []);

  // const scrubbedEvents = useMemo(
  //   () =>
  //     rulesetState.rulesets.all.reduce((acc, ruleset) => {
  //       const events = rulesetState.events.all.filter(
  //         (e) => e.ruleset_id === ruleset.id,
  //       );
  //       const devices = ruleset.devices.reduce((a, d) => {
  //         if (d) {
  //           return [...a, d];
  //         }
  //         return a;
  //       }, [] as string[]);
  //
  //       return [...acc, ...events.filter((e) => devices.includes(e.device_id))];
  //     }, [] as RulesetEvent[]),
  //   [rulesetState.rulesets.all, rulesetState.events.all],
  // );

  useEffect(() => {
    if (timeToReload === undefined) {
      update_check.updateCheck(config.updateCheckTimer, () => {
        setTimeToReload(DateTime.utc().plus({ minutes: 5 }));
      });
    }
  }, [config.updateCheckTimer, timeToReload]);

  const setCurrentPage = useMemo(
    () =>
      (path: string): void =>
        navigate(path),
    [navigate],
  );

  const tabs = useMemo(
    () => [
      {
        label: <FormattedMessage id="appbar.map" defaultMessage="Map" />,
        icon: MapIcon,
        path: '/',
      },
      {
        label: (
          <FormattedMessage id="appbar.projects" defaultMessage="Projects" />
        ),
        icon: FlagIcon,
        path: '/projects',
      },
      {
        label: <FormattedMessage id="appbar.rules" defaultMessage="Rules" />,
        icon: RuleIcon,
        path: '/rules',
      },
      {
        label: <FormattedMessage id="appbar.groups" defaultMessage="Groups" />,
        icon: GroupIcon,
        path: '/groups',
      },
    ],
    [],
  );

  const currentTab = useMemo(() => {
    if (location.pathname.startsWith('/projects')) {
      return 1;
    }
    if (location.pathname.startsWith('/rules')) {
      return 2;
    }
    if (location.pathname.startsWith('/groups')) {
      return 3;
    }

    return 0;
  }, [location.pathname]);

  const MemoizedProjectsView = useMemo(() => <ProjectsView />, []);

  const MemoizedMapView = useMemo(
    () => <MapView admin={!!token?.isAdmin()} />,
    [token],
  );

  if (!config) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <AllbinAppBar
        setCurrentPage={setCurrentPage}
        tabs={tabs}
        currentTab={currentTab}
        locale={props.locale}
        setLocale={props.setLocale}
      />
      <Box
        sx={{
          marginTop: ({ appBarHeight }) => `${appBarHeight}px`,
          flex: '1 1 auto',
          display: 'flex',
          flexFlow: 'column',
        }}
      >
        <Suspense
          fallback={
            <Box width="100%">
              <LinearProgress variant="indeterminate" />
            </Box>
          }
        >
          <SentryRoutes>
            <Route path="/projects/:id" element={MemoizedProjectsView} />
            <Route path="/projects" element={MemoizedProjectsView} />
            <Route path="/rules/:rulesetId" element={<RulesView />} />
            <Route path="/rules" element={<RulesView />} />
            <Route path="/groups/:id" element={<GroupsView />} />
            <Route path="/groups" element={<GroupsView />} />
            <Route path="/" element={MemoizedMapView} />
            <Route path="/:deviceId" element={MemoizedMapView} />
            <Route path="/:deviceId/edit" element={MemoizedMapView} />
            <Route path="/:deviceId/remap" element={MemoizedMapView} />
          </SentryRoutes>
        </Suspense>
      </Box>
      {timeToReload && (
        <ReloadWindowDialog
          reloadAt={timeToReload}
          onReload={handleWindowReload}
        />
      )}
      <Snackbar open={!connected}>
        <SnackbarContent
          message={
            <FormattedMessage
              id="main_view.connecting_to_senseview"
              defaultMessage="Connecting to SenseView"
            />
          }
          sx={{ backgroundColor: 'secondary.light' }}
        />
      </Snackbar>
    </Box>
  );
};

export default MainView;
