import produce from 'immer';
import { useCallback } from 'react';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { useGroups } from './groups';

export interface CurrentUser extends User {
  uid: string;
  admin: boolean;
}

interface UserStore {
  list: User[];
  lookup: Record<string, User>;
  currentUser?: CurrentUser;
  load: (users: ServerUser[]) => void;
  setCurrentUser: (currentUser: CurrentUser) => void;
}
export const serverUserToUser = (u: ServerUser): User =>
  ({
    ...u,
    full_name: u.last_name ? `${u.first_name} ${u.last_name}` : u.first_name,
    initials:
      u.first_name[0] + (u.last_name ? u.last_name[0] : u.first_name[0]),
  } as User);

export const useUser = create<UserStore>(
  devtools(
    (set) => ({
      list: [],
      lookup: {},
      load: (users: ServerUser[]) =>
        set(
          produce((state: UserStore) => {
            state.list = users.map(serverUserToUser);
            state.lookup = users.reduce((acc, u) => {
              return { ...acc, [u.id]: serverUserToUser(u) };
            }, {});
          }),
        ),
      setCurrentUser: (currentUser) =>
        set(
          produce((state: UserStore) => {
            if (state.currentUser?.id !== currentUser.id) {
              state.currentUser = currentUser;
            }
          }),
        ),
    }),
    { name: 'useUser' },
  ),
);

export const useCanEdit = (deviceId?: string) => {
  const groups = useGroups(useCallback((state) => state.list, []));
  return useUser(
    useCallback(
      (state) => {
        if (state.currentUser === undefined || !deviceId) {
          return false;
        }

        if (state.currentUser.admin) {
          return true;
        }

        const uid = `${state.currentUser.id}`;

        return (
          groups.filter(
            (group) =>
              group.users[uid]?.admin && group.devices.includes(deviceId),
          ).length > 0
        );
      },
      [groups, deviceId],
    ),
  );
};
