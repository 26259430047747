import { useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { useConfig } from '../config';
import api_helpers, {
  getEventMessage,
  WebsocketEvent,
} from '../helpers/api_helpers';
import { ServerDeviceData } from '../models/DataPoint';
import { ServerDevice } from '../models/Device';
import { Insight } from '../models/Insight';
import { Project } from '../models/Project';
import { Ruleset, RulesetEvent } from '../models/Ruleset';
import { useConnection } from '../store/connected';
import { useAddDataPoint } from '../store/datapoints';
import { useDevices } from '../store/devices';
import { useInsights, useProjects } from '../store/project';
import { useRulesets } from '../store/rulesets';

export const useWebsocket = () => {
  const queryClient = useQueryClient();
  const setConnected = useConnection(
    useCallback((state) => state.setConnected, []),
  );

  const websocketUrl = useConfig(
    useCallback(
      (config) =>
        config.api.senseview.api_uri.replace('http', 'ws').concat('/ws'),
      [],
    ),
  );

  const { setDevice, removeDevice } = useDevices(
    useCallback(
      (state) => ({
        setDevice: state.setDevice,
        removeDevice: state.removeDevice,
      }),
      [],
    ),
  );
  const { setProject, deleteProject } = useProjects(
    useCallback(
      (state) => ({
        setProject: state.setProject,
        deleteProject: state.deleteProject,
      }),
      [],
    ),
  );
  const { setInsight, deleteInsight } = useInsights(
    useCallback(
      (state) => ({
        setInsight: state.setInsight,
        deleteInsight: state.deleteInsight,
      }),
      [],
    ),
  );
  const { setRuleset, deleteRuleset, setEvent, clearEvent } = useRulesets(
    useCallback(
      (state) => ({
        setRuleset: state.setRuleset,
        deleteRuleset: state.deleteRuleset,
        setEvent: state.setEvent,
        clearEvent: state.clearEvent,
      }),
      [],
    ),
  );

  const addDataPoint = useAddDataPoint();

  const handleEvent = useCallback(
    (event: WebsocketEvent<any>) => {
      switch (event.type) {
        case 'LOGIN':
        case 'UPLINK_ADD': {
          // Ignore these events
          break;
        }
        case 'DATAPOINT_ADD': {
          addDataPoint(getEventMessage<ServerDeviceData>(event));
          break;
        }
        case 'DEVICE_UPDATE':
        case 'DEVICE_CREATE': {
          setDevice(getEventMessage<ServerDevice>(event));
          break;
        }
        case 'DEVICE_DELETE': {
          removeDevice(getEventMessage<ServerDevice>(event).id);
          // TODO remove known datapoints as well
          break;
        }
        case 'PROJECT_CREATE':
        case 'PROJECT_UPDATE': {
          setProject(getEventMessage<Project>(event));
          break;
        }
        case 'PROJECT_DELETE': {
          deleteProject(getEventMessage<Project>(event).id);
          break;
        }
        case 'INSIGHT_CREATE':
        case 'INSIGHT_UPDATE': {
          setInsight(getEventMessage<Insight>(event));
          break;
        }
        case 'INSIGHT_DELETE': {
          deleteInsight(getEventMessage<Insight>(event).id);
          break;
        }
        case 'RULESET_CREATE':
        case 'RULESET_UPDATE': {
          setRuleset(getEventMessage<Ruleset>(event));
          break;
        }
        case 'RULESET_DELETE': {
          deleteRuleset(getEventMessage<Ruleset>(event).id);
          break;
        }
        case 'RULESET_EVENT_ACTIVE': {
          setEvent(getEventMessage<RulesetEvent>(event));
          break;
        }
        case 'RULESET_EVENT_CLEAR': {
          clearEvent(getEventMessage<RulesetEvent>(event));
          break;
        }
        case 'GROUP_CREATE':
        case 'GROUP_DELETE':
        case 'GROUP_UPDATE': {
          // FIXME This is a bit like using a sledgehammer to hang a painting

          void queryClient.invalidateQueries('devices');
          // void queryClient.invalidateQueries('models');
          // void queryClient.invalidateQueries('manufacturers');
          void queryClient.invalidateQueries('data');
          void queryClient.invalidateQueries('projects');
          void queryClient.invalidateQueries('insights');
          void queryClient.invalidateQueries('rulesets');
          void queryClient.invalidateQueries('rulesetEvents');
          void queryClient.invalidateQueries('transforms');
          // void queryClient.invalidateQueries('users');
          void queryClient.invalidateQueries('groups');
          break;
        }
        default:
          console.log('unhandled event', event);
          break;
      }
    },
    [
      addDataPoint,
      clearEvent,
      deleteInsight,
      deleteProject,
      deleteRuleset,
      queryClient,
      removeDevice,
      setDevice,
      setEvent,
      setInsight,
      setProject,
      setRuleset,
    ],
  );

  useEffect(() => {
    console.log('connecting to websocket at', websocketUrl);
    const ws = api_helpers.connectWebsocket(
      websocketUrl,
      handleEvent,
      setConnected,
    );
    return () => {
      console.log('closing websocket');
      ws?.close();
    };
  }, [handleEvent, setConnected, websocketUrl]);
};
