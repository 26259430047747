import axios from 'axios';
import React, { useCallback } from 'react';
import { useQuery } from 'react-query';

import { useSpinner } from './components/Spinner';
import { Config, useConfig } from './config';

export const ConfigProvider: React.FC = ({ children }) => {
  const wrapSpinner = useSpinner(useCallback((state) => state.wrap, []));
  const load = useConfig(useCallback((state) => state.load, []));

  const { isFetched: fetched, isError: error } = useQuery('config', () =>
    wrapSpinner(
      'config',
      axios
        .get<Partial<Config>>('/config.json')
        .then((resp) => resp.data)
        .then(load),
    ),
  );

  if (!fetched || error) {
    return null;
  }

  return <>{children}</>;
};

export default ConfigProvider;
