import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

interface Options {
  name?: string;
  table?: boolean;
}

export function useTraceUpdate(props: unknown, options?: Options): void {
  const prev = useRef(props as any);
  useEffect(() => {
    const changedProps: any = Object.entries(props as any).reduce(
      (ps: any, [k, v]) => {
        if (prev.current[k] !== v) {
          ps[k] = [prev.current[k], v];
        }
        return ps as unknown;
      },
      {},
    );
    if (Object.keys(changedProps).length > 0) {
      if (options?.name) {
        console.group(options.name);
      }

      if (options?.table) {
        console.table(changedProps);
      } else if (options?.name) {
        console.log(changedProps);
      } else {
        console.log('Changed props:', changedProps);
      }

      if (options?.name) {
        console.groupEnd();
      }
    }
    prev.current = props;
  });
}

export const withTrace =
  <P extends unknown>(Component: React.ComponentType<P>): React.FC<P> =>
  (props) => {
    useTraceUpdate(props);
    return <Component {...props} />;
  };

export function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}
