import React, { useCallback, useMemo } from 'react';

import { formatDataPoint } from '../helpers/data_helpers';
import { getRemap } from '../helpers/device_helpers';
import { ServerDeviceData } from '../models/DataPoint';
import { DeviceModel, DeviceSensor } from '../models/Device';
import { useDataPoints } from '../store/datapoints';
import { useDevices } from '../store/devices';
import { useModels } from '../store/models';
import { useTransforms } from '../store/transforms';

interface Props {
  deviceId: string;
  sensorId: string;
}

function getSensor(
  sensorId: string,
  transforms: Transform[],
  model: DeviceModel,
): Pick<DeviceSensor, 'type' | 'suffix'> | undefined {
  const [id] = sensorId.split(':');

  if (id === 'transform') {
    const transformId = parseInt(sensorId.split(':')[1]);
    if (isNaN(transformId)) {
      return undefined;
    }
    const transform = transforms.find((t) => t.id === transformId);
    if (!transform) {
      return undefined;
    }

    return {
      type: transform.icon as SensorType,
      suffix: transform.suffix,
    };
  }
  return model?.sensors[id];
}

export const SensorValue: React.FC<Props> = ({ deviceId, sensorId }) => {
  const device = useDevices(
    useCallback(({ lookup }) => lookup[deviceId], [deviceId]),
  );
  const model = useModels(
    useCallback(({ lookup }) => lookup[device?.model_id], [device?.model_id]),
  );
  const transforms = useTransforms(
    useCallback(
      ({ transforms }) =>
        transforms.filter((t) => t.targets.some((t) => t.device === deviceId)),
      [deviceId],
    ),
  );

  const dataPoint: ServerDeviceData | undefined = useDataPoints(
    useCallback(
      ({ data }) => !!data[deviceId] && data[deviceId][sensorId],
      [deviceId, sensorId],
    ),
  );

  const displayValue = useMemo(() => {
    if (!dataPoint) {
      return '-';
    }
    const remap = getRemap(
      device?.meta.sensors[sensorId]?.remap,
      dataPoint?.value || [],
    );
    if (remap?.output) {
      return remap.output;
    }

    const sensor = getSensor(sensorId, transforms, model);
    return formatDataPoint(dataPoint, sensor);
  }, [dataPoint, device?.meta.sensors, model, sensorId, transforms]);

  // useEffect(() => {
  //   console.log('updated datapoint', {
  //     device: dataPoint.device_id,
  //     sensor: dataPoint.sensor_id,
  //     value: dataPoint?.value.join(', '),
  //   });
  // }, [dataPoint]);

  return <>{displayValue}</>;
};

export default SensorValue;
