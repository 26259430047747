import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { useSpinner } from './components/Spinner';
import { useConfig } from './config';

interface Version {
  version?: string;
}

interface UseSentry {
  loaded: boolean;
}

export const useSentry = (): UseSentry => {
  const wrapSpinner = useSpinner(useCallback((state) => state.wrap, []));

  const [loaded, setLoaded] = useState(false);
  const { serviceName, isConfigLoaded } = useConfig(
    useCallback(
      ({ isLoaded, service_name }) => ({
        isConfigLoaded: isLoaded,
        serviceName: service_name,
      }),
      [],
    ),
  );

  useEffect(() => {
    if (!isConfigLoaded) {
      return;
    }

    void wrapSpinner(
      'sentry',
      axios
        .get<Version>('/version.json')
        .then(({ data }) => data.version)
        .then((version) => {
          Sentry.init({
            enabled: !!version && !version.includes('internal'),
            dsn: 'https://308d7cd24924435a99fce047f29d2a8c@o1409683.ingest.sentry.io/6768193',
            tunnel: 'https://sentry.allbin.se',
            integrations: [
              new BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                  useEffect,
                  useLocation,
                  useNavigationType,
                  createRoutesFromChildren,
                  matchRoutes,
                ),
              }),
            ],
            environment: serviceName,
            release: version || '0.0.0-internal',

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
          });
          setLoaded(true);
        }),
    );
  }, [isConfigLoaded, serviceName, wrapSpinner]);

  return { loaded };
};
