import { Lookup } from '../contexts/DataContext';
import {
  DeviceData,
  DisplayValue,
  SensorDataPoint,
  ServerDeviceData,
} from '../models/DataPoint';
import { DeviceSensor } from '../models/Device';
import { metricPrefix } from './metric';

export const unpackDataPoint = (point: ServerDeviceData): SensorDataPoint => {
  return {
    id:
      point.model_id === 'transform'
        ? `${point.model_id}:${point.sensor_id}`
        : point.sensor_id,
    time: point.time,
    value: point.value,
  } as SensorDataPoint;
};

export function unpack(deviceData: ServerDeviceData[]): Lookup<DeviceData> {
  const deviceDataById: Lookup<DeviceData> = {};

  if (deviceData === null) {
    return deviceDataById;
  }

  deviceData.forEach((packedSensorData) => {
    const point = unpackDataPoint(packedSensorData);

    // New device
    if (
      Object.prototype.hasOwnProperty.call(
        deviceDataById,
        packedSensorData.device_id,
      ) === false
    ) {
      deviceDataById[packedSensorData.device_id] = {
        sensors: {},
        last_seen: packedSensorData.time,
      };
    }

    // New sensor
    if (
      Object.prototype.hasOwnProperty.call(
        deviceDataById[packedSensorData.device_id].sensors,
        packedSensorData.sensor_id,
      ) === false
    ) {
      deviceDataById[packedSensorData.device_id].sensors[point.id] = [];
    }

    // Add data point
    deviceDataById[packedSensorData.device_id].sensors[point.id].push(point);

    const lastSeen = deviceDataById[packedSensorData.device_id].last_seen;
    if (!lastSeen || point.time > lastSeen) {
      deviceDataById[packedSensorData.device_id].last_seen = point.time;
    }
  });

  return deviceDataById;
}

// const fixRounding = (value: number, precision: number): number => {
//   const power = Math.pow(10, precision || 0);
//   return Math.round(value * power) / power;
// };

export function getSuffix(type?: SensorType): string {
  switch (type) {
    case 'temperature': {
      return '°C';
    }
    case 'percent':
    case 'humidity': {
      return '%';
    }
    case 'co2': {
      return 'ppm';
    }
    case 'volts': {
      return 'V';
    }
    case 'pressure': {
      return 'Pa';
    }
    case 'distance': {
      return 'm';
    }
    case 'sound': {
      return 'dBA';
    }
    case 'light': {
      return 'lux';
    }
    case 'dbm': {
      return 'dBm';
    }
    case 'db': {
      return 'dB';
    }
    case 'weight': {
      return 'g';
    }
    case 'frequency': {
      return 'Hz';
    }
    case 'meters-per-second': {
      return 'm/s';
    }
    case 'angle': {
      return '°';
    }
    case 'solar-radiation': {
      return 'W/m²';
    }
    case 'meters-per-hour': {
      return 'm/h';
    }
  }
  return '';
}

export function formatDataPoint(
  dataPoint: SensorDataPoint | ServerDeviceData | string,
  sensor?: Pick<DeviceSensor, 'type' | 'suffix'>,
): string {
  const formatters: Partial<Record<SensorType, (value: number[]) => string>> = {
    temperature: (value) => {
      return `${value[0].toFixed(1)} ${getSuffix(sensor?.type)}`;
    },
    humidity: (value) => {
      return `${value[0].toFixed(1)} ${getSuffix(sensor?.type)}`;
    },
    co2: (value) => {
      return `${value[0].toFixed(1)} ${getSuffix(sensor?.type)}`;
    },
    volts: (value) => {
      if (value[0] < 1) {
        return `${(value[0] * 1000).toFixed(0)} m${getSuffix(sensor?.type)}`;
      }
      return `${value[0].toFixed(2)} ${getSuffix(sensor?.type)}`;
    },
    acceleration: (value) => {
      return value
        .map((v) => (v === undefined ? '-' : v.toFixed(1)))
        .join(', ');
    },
    pressure: (value) => {
      return metricPrefix(value[0], {
        unit: getSuffix(sensor?.type),
        whitelist: ['', 'h'],
      });
      // return value[0] < 1000
      //   ? `${(value[0] / 100).toFixed(1)} h${getSuffix(sensor?.type)}`
      //   : `${(value[0] / 1000).toFixed(1)} k${getSuffix(sensor?.type)}`;
    },
    distance: (value) => {
      return metricPrefix(value[0], {
        unit: getSuffix(sensor?.type),
        whitelist: ['μ', 'm', 'c', 'd', '', 'k'],
      });
      // return `${fixRounding(value[0] * 1000, 8)} m${getSuffix(sensor?.type)}`;
    },
    percent: (value) => {
      return `${(value[0] * 100).toFixed(1)} ${getSuffix(sensor?.type)}`;
    },
    sound: (value) => {
      return `${value[0].toFixed(1)} ${getSuffix(sensor?.type)}`;
    },
    gps: (value) => {
      return value
        .map((v) => (v === undefined ? '-' : v.toFixed(7)))
        .join(', ');
    },
    weight: (value) => {
      return `${value[0].toFixed(1)} ${getSuffix(sensor?.type)}`;
    },
    frequency: (value) => {
      return `${value[0].toFixed(1)} ${getSuffix(sensor?.type)}`;
    },
    'meters-per-second': (value) => {
      return metricPrefix(value[0], {
        unit: getSuffix(sensor?.type),
        whitelist: [''],
      });
    },
    'meters-per-hour': (value) => {
      return metricPrefix(value[0], {
        unit: getSuffix(sensor?.type),
        whitelist: ['m'],
      });
    },
    'solar-radiation': (value) => {
      return metricPrefix(value[0], {
        unit: getSuffix(sensor?.type),
        whitelist: ['m', '', 'k', 'M'],
      });
    },
    angle: (value) => {
      return `${value[0].toFixed(1)} ${getSuffix(sensor?.type)}`;
    },
  };

  if (typeof dataPoint === 'string') {
    return dataPoint;
  }

  if (sensor) {
    if (sensor.suffix) {
      return `${dataPoint.value} ${sensor.suffix}`;
    }

    const fmt = formatters[sensor.type];
    if (fmt) {
      return fmt(dataPoint.value);
    }
  }
  // console.log(dataPoint);
  return `${dataPoint.value[0].toFixed(0)} ${getSuffix(sensor?.type)}`;
}

export const createDisplayValue = (
  data: SensorDataPoint | ServerDeviceData,
  sensor?: DeviceSensor,
  remap?: SensorRemap,
): DisplayValue => {
  return {
    time: data.time,
    raw: data.value,
    formatted: formatDataPoint(data, sensor),
    alternative: remap && remap.output,
    color: remap && remap.color,
  };
};
