import { Lookup } from '../contexts/DataContext';
import {
  Device,
  DeviceModel,
  DeviceSensor,
  DeviceUpdate,
  ServerDevice,
} from '../models/Device';
import { getSuffix } from './data_helpers';

export function pack(device: Device): DeviceUpdate {
  const sensors = Object.keys(device.meta.sensors).reduce<
    Record<string, ServerMetaSensor>
  >((sensors, key) => {
    return {
      ...sensors,
      [key]: {
        widget: device.meta.sensors[key].widget,
        description: device.meta.sensors[key].description,
        name: device.meta.sensors[key].name,
        remap: device.meta.sensors[key].remap,
      },
    };
  }, {});

  return {
    id: device.id,
    name: device.name,
    description: device.description,
    public: device.public,
    model_id: device.model_id,
    meta: { ...device.meta, sensors },
  };
}

export function unpack(
  serverDevice: ServerDevice,
  model?: DeviceModel,
): Device {
  return {
    ...serverDevice,
    name: serverDevice.name || serverDevice.id,
    description: serverDevice.description || '',
    meta: {
      ...serverDevice.meta,
      sensors: Object.keys(serverDevice.meta.sensors || {}).reduce<
        Record<string, Sensor>
      >((sensors, id) => {
        return {
          ...sensors,
          [id]: {
            id,
            type: model?.sensors[id]?.type || 'number',
            ...serverDevice.meta.sensors[id],
          },
        };
      }, {}),
    },
  };
}

export function deviceSorter(a: ServerDevice, b: ServerDevice): number {
  const name = a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  if (name !== 0) {
    return name;
  }

  return a.id.toLowerCase().localeCompare(b.id.toLowerCase());
}

export const getRemap = (
  remaps: SensorRemap[] | undefined,
  data: number[],
): SensorRemap | undefined => {
  if (!remaps) {
    return undefined;
  }
  return remaps.reduce((acc, r) => {
    // FIXME: currently only handles single-value data, multidimensional values to be added later

    // Do not compare if input values and data values are different length (ex. GPS can not compare to single value)
    if (r.input.length === data.length) {
      switch (r.op) {
        case 'eq':
          if (data[0] === r.input[0]) {
            return r;
          }
          break;
        case 'gt':
          if (data[0] > r.input[0]) {
            return r;
          }
          break;
        case 'gte':
          if (data[0] >= r.input[0]) {
            return r;
          }
          break;
        case 'lt':
          if (data[0] < r.input[0]) {
            return r;
          }
          break;
        case 'lte':
          if (data[0] <= r.input[0]) {
            return r;
          }
          break;
      }
    }
    return acc;
  }, undefined as SensorRemap | undefined);
};

export const getSensorsLookup = (model: DeviceModel): Lookup<DeviceSensor> => {
  const lookup = {} as { [id: string]: DeviceSensor };

  if (!model) {
    return lookup;
  }
  Object.keys(model.sensors).forEach((k) => {
    lookup[k] = {
      id: k,
      name: model.sensors[k].name,
      type: model.sensors[k].type,
    };
  });

  return lookup;
};

export const getSensors = (model?: DeviceModel): Array<DeviceSensor> => {
  if (!model) {
    return [];
  }

  return Object.keys(model.sensors).map((k) => {
    return {
      id: k,
      name: model.sensors[k].name,
      type: model.sensors[k].type,
      suffix: getSuffix(model.sensors[k].type),
    };
  });
};
