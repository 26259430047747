import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Fab,
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
} from '@mui/material';
import React, { FC, memo, ReactElement, useState } from 'react';

import AllbinAppDrawer from './AllbinAppDrawer';

export interface AppBarTab {
  label: ReactElement | string;
  icon?: any;
  path: string;
}

interface AllbinAppBarProps {
  tabs: Array<AppBarTab>;
  currentTab: number;
  setCurrentPage: (tab: string) => void;
  locale: string;
  setLocale: (locale: string) => void;
}

const AllbinAppBar: FC<AllbinAppBarProps> = ({
  setCurrentPage,
  tabs,
  currentTab,
  locale,
  setLocale,
}) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <AppBar
      color="inherit"
      sx={{
        height: ({ appBarHeight }) => appBarHeight,
      }}
    >
      <Toolbar
        sx={{
          '&': {
            height: ({ appBarHeight }) => appBarHeight,
            minHeight: ({ appBarHeight }) => appBarHeight,
          },
        }}
      >
        <Grid container justifyContent="flex-end">
          <Grid item xs={8}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              {tabs.map((tab, i) => {
                return (
                  <Box marginRight={1} key={tab.path}>
                    <Tooltip title={tab.label}>
                      <Fab
                        sx={{
                          boxShadow: 'none',
                          transition: '0.4s',
                          height: 40,
                          borderRadius: 20,
                          textTransform: 'none',
                          '&.MuiFab-colorInherit': {
                            width: 40,
                            backgroundColor: ({ palette }) =>
                              palette.accentVariant.main,
                            border: 0,
                            color: ({ palette }) => palette.text.hint,
                          },
                        }}
                        color={i === currentTab ? 'primary' : 'inherit'}
                        variant={i === currentTab ? 'extended' : 'circular'}
                        size="medium"
                        onClick={(e) => {
                          e.preventDefault();
                          setCurrentPage(tab.path);
                        }}
                      >
                        {tab.icon && <tab.icon />}
                        <Box display={i !== currentTab ? 'none' : 'inherit'}>
                          &nbsp;{tab.label}
                        </Box>
                      </Fab>
                    </Tooltip>
                  </Box>
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" justifyContent="flex-end">
              <IconButton
                color="inherit"
                aria-label="menu"
                onClick={() => {
                  setShowMenu(true);
                }}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <AllbinAppDrawer
          open={showMenu}
          onClose={() => setShowMenu(false)}
          locale={locale}
          onLocale={setLocale}
        />
      </Toolbar>
    </AppBar>
  );
};

export default memo(AllbinAppBar);
