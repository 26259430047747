import axios from 'axios';
import produce from 'immer';
import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { toLookup } from '../helpers/lookup';

type GroupImage = {
  url: string;
  image?: string;
  fetching: boolean;
  fetched: boolean;
  error?: Error;
};

interface GroupState {
  list: Group[];
  lookup: Record<number, Group>;
  load: (groups: Group[]) => void;
  images: Record<string, GroupImage>;
  loadImages: (images: GroupImage[]) => void;
  setImage: (image: GroupImage) => void;
}

export const useGroups = create<GroupState>(
  devtools(
    (set) => ({
      list: [],
      lookup: {},
      images: {},
      load: (groups) =>
        set(
          produce((state) => {
            state.list = groups ? [...groups].sort(sortGroups) : [];
            state.lookup = groups ? toLookup(groups) : {};
          }),
        ),
      loadImages: (images) =>
        set(
          produce((state) => {
            state.images = images.reduce(
              (acc, image) => ({
                ...acc,
                [image.url]: image,
              }),
              {},
            );
          }),
        ),
      setImage: (image: GroupImage) =>
        set(
          produce((state) => {
            state.images[image.url] = image;
          }),
        ),
    }),
    // (set, get) => ({
    //   groups: {
    //     all: [],
    //     lookup: {},
    //     fetching: false,
    //     fetched: false,
    //   },
    //   images: {},
    //   setGroups: (groups) =>
    //     set(() => ({
    //       groups: {
    //         all: groups.sort(sortGroups),
    //         lookup: toLookup(groups),
    //         fetched: true,
    //         fetching: false,
    //       },
    //     })),
    //   fetchGroups: () => {
    //     set((state) => ({ groups: { ...state.groups, fetching: true } }));
    //     return api_helpers
    //       .fetchGroups()
    //       .then((groups) => {
    //         const sorted = groups.sort(sortGroups);
    //         set((state) => ({
    //           groups: {
    //             ...state.groups,
    //             all: sorted,
    //             lookup: toLookup(groups),
    //             fetched: true,
    //             fetching: false,
    //           },
    //         }));
    //         return sorted;
    //       })
    //       .catch(() => {
    //         set((state) => ({
    //           groups: {
    //             ...state.groups,
    //             fetching: false,
    //             fetched: false,
    //           },
    //         }));
    //         return [];
    //       });
    //   },
    //   createGroup: (group) =>
    //     api_helpers
    //       .createGroup(group)
    //       .then((created) => {
    //         set((state) => ({
    //           groups: {
    //             ...state.groups,
    //             all: [
    //               ...state.groups.all.filter((g) => g.id !== created.id),
    //               created,
    //             ].sort(sortGroups),
    //             lookup: { ...state.groups.lookup, [created.id]: created },
    //           },
    //         }));
    //         return created;
    //       })
    //       .catch((error) => {
    //         throw new Error('failed to create group: ' + error);
    //       }),
    //   updateGroup: (group: Group) =>
    //     api_helpers
    //       .updateGroup(group)
    //       .then((updated) => {
    //         set((state) => {
    //           return {
    //             groups: {
    //               ...state.groups,
    //               all: upsertArray(state.groups.all, group, sortGroups),
    //               lookup: { ...state.groups.lookup, [updated.id]: updated },
    //             },
    //           };
    //         });
    //         return updated;
    //       })
    //       .catch((err) => {
    //         throw new Error('failed to update group: ' + err);
    //       }),
    //   deleteGroup: (id: number) =>
    //     api_helpers
    //       .removeGroup(id)
    //       .then(() => {
    //         set((state) => ({
    //           groups: {
    //             ...state.groups,
    //             all: state.groups.all.filter((g) => g.id !== id),
    //             lookup: without(state.groups.lookup, id),
    //           },
    //         }));
    //       })
    //       .catch((error) => {
    //         throw new Error('failed to delete group: ' + error);
    //       }),
    //   fetchImages: () => {
    //     const images = [
    //       ...new Set(
    //         get().groups.all.reduce<string[]>((acc, group) => {
    //           if (!group.image) {
    //             return acc;
    //           }
    //           return [...acc, group.image];
    //         }, []),
    //       ),
    //     ];
    //     // Mark images as loading
    //     set({
    //       images: images.reduce<Lookup<GroupImage>>(
    //         (acc, url) => ({
    //           ...acc,
    //           [url]: { url, fetching: true, fetched: false },
    //         }),
    //         {},
    //       ),
    //     });
    //
    //     return Promise.all(images.map((img) => fetchImage(img))).then((res) => {
    //       set(() => ({
    //         images: res.reduce<Lookup<GroupImage>>(
    //           (acc, groupImage) => ({ ...acc, [groupImage.url]: groupImage }),
    //           {},
    //         ),
    //       }));
    //     });
    //   },
    // }),
    { name: 'useGroups' },
  ),
);

const fetchImage = (url: string): Promise<GroupImage> =>
  axios
    .get(url, {
      responseType: 'arraybuffer',
    })
    .then((res) => {
      return {
        url,
        fetching: false,
        fetched: true,
        image: `data:${res.headers['content-type'].toLowerCase()};base64,${btoa(
          new Uint8Array(res.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
          ),
        )}`,
      };
    })
    .catch((err) => {
      return {
        url,
        fetching: false,
        fetched: false,
        error: err,
      };
    });

export const useGroupImages = () => {
  const { groups, setImage } = useGroups(
    useCallback(
      (state) => ({
        groups: state.list,
        images: state.images,
        setImage: state.setImage,
      }),
      [],
    ),
  );

  const images = useMemo<string[]>(
    () =>
      groups
        .filter((g) => !!g.image)
        .map((g) => g.image)
        .filter((url, i, arr) => arr.indexOf(url) === i),
    [groups],
  );

  useQuery(['fetchImage', ...images], async ({ queryKey }) =>
    Promise.all(
      queryKey.splice(1).map((url) => {
        setImage({ url, fetching: true, fetched: false, error: undefined });
        return fetchImage(url).then(setImage);
      }),
    ),
  );
};

const sortGroups = (a: Group, b: Group): number => a.name.localeCompare(b.name);
