import produce from 'immer';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { Insight } from '../models/Insight';
import { Project } from '../models/Project';

interface ProjectsStore {
  list: Project[];
  lookup: Record<number, Project>;
  load: (projects: Project[]) => void;
  setProject: (project: Project) => void;
  deleteProject: (id: number) => void;
}

export const useProjects = create<ProjectsStore>(
  devtools(
    (set) => ({
      list: [],
      lookup: {},
      load: (projects) =>
        set(
          produce((state) => {
            state.list = projects;
            state.lookup = projects.reduce<Record<number, Project>>(
              (acc, project) => {
                acc[project.id] = project;
                return acc;
              },
              {},
            );
          }),
        ),
      setProject: (project) =>
        set(
          produce((state: ProjectsStore) => {
            if (state.lookup[project.id] === undefined) {
              state.list.push(project);
              state.lookup[project.id] = project;
              return;
            }
            const i = state.list.findIndex(
              (p) => p.id === project.id && p.updated_at < project.updated_at,
            );
            if (i === -1) {
              return;
            }

            state.list[i] = project;
            state.lookup[project.id] = project;
          }),
        ),
      deleteProject: (id) =>
        set(
          produce((state: ProjectsStore) => {
            if (state.lookup[id] === undefined) {
              return;
            }

            const i = state.list.findIndex((p) => p.id === id);
            if (i === -1) {
              return;
            }

            state.list.splice(i, 1);
            delete state.lookup[id];
          }),
        ),
    }),
    { name: 'useProjects' },
  ),
);

interface InsightsStore {
  list: Insight[];
  lookup: Record<number, Insight>;
  load: (insights: Insight[]) => void;
  setInsight: (insight: Insight) => void;
  deleteInsight: (id: number) => void;
}

export const useInsights = create<InsightsStore>(
  devtools(
    (set) => ({
      list: [],
      lookup: {},
      load: (insights) =>
        set(
          produce((state) => {
            state.list = insights;
            state.lookup = insights.reduce<Record<number, Insight>>(
              (acc, insight) => {
                acc[insight.id] = insight;
                return acc;
              },
              {},
            );
          }),
        ),
      setInsight: (insight) =>
        set(
          produce((state: InsightsStore) => {
            if (state.lookup[insight.id] === undefined) {
              state.list.push(insight);
              state.lookup[insight.id] = insight;
              return;
            }
            const i = state.list.findIndex(
              (p) => p.id === insight.id && p.updated_at < insight.updated_at,
            );
            if (i === -1) {
              return;
            }

            state.list[i] = insight;
            state.lookup[insight.id] = insight;
          }),
        ),
      deleteInsight: (id) =>
        set(
          produce((state: InsightsStore) => {
            if (state.lookup[id] === undefined) {
              return;
            }

            const i = state.list.findIndex((p) => p.id === id);
            if (i === -1) {
              return;
            }

            state.list.splice(i, 1);
            delete state.lookup[id];
          }),
        ),
    }),
    { name: 'useInsights' },
  ),
);
