export type UnitPrefix = 'μ' | 'm' | 'c' | 'd' | '' | 'h' | 'k' | 'M';

interface MetricPrefixOptions {
  unit?: string;
  whitelist?: UnitPrefix[];
  decimals?: number;
}

const fmt = (value: string, unit: string, prefix: UnitPrefix): string => {
  if (!unit && !prefix) {
    return `${value}`;
  }
  return `${value} ${prefix}${unit}`;
};

const round = (value: number, decimals?: number): string =>
  value.toFixed(decimals !== undefined ? decimals : 1);

interface Breakpoint {
  factor: number;
  prefix: UnitPrefix;
}

const breakpoints: Breakpoint[] = [
  { factor: 1e-6, prefix: 'μ' },
  { factor: 1e-3, prefix: 'm' },
  { factor: 1e-2, prefix: 'c' },
  { factor: 1e-1, prefix: 'd' },
  { factor: 1, prefix: '' },
  { factor: 1e2, prefix: 'h' },
  { factor: 1e3, prefix: 'k' },
  { factor: 1e6, prefix: 'M' },
];

export const metricPrefix = (
  value: number,
  options?: MetricPrefixOptions,
): string => {
  const breakpoint =
    value === 0
      ? ({ factor: 1, prefix: '' } as Breakpoint)
      : breakpoints.reduce<Breakpoint | undefined>((prev, curr) => {
          if (options?.whitelist && !options.whitelist.includes(curr.prefix)) {
            return prev;
          }

          if (!prev) {
            return curr;
          }

          return Math.abs(curr.factor - value) < Math.abs(prev.factor - value)
            ? curr
            : prev;
        }, undefined);

  if (!breakpoint || breakpoint.prefix === '') {
    return fmt(round(value, options?.decimals), options?.unit || '', '');
  }

  return fmt(
    round(value / breakpoint.factor, options?.decimals),
    options?.unit || '',
    breakpoint.prefix,
  );
};
