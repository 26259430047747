import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import { useConfig } from '../config';
import api_helpers from '../helpers/api_helpers';
import { useDataDisplayUpdater } from '../hooks/useDataDisplay';
import { useWebsocket } from '../hooks/useWebsocket';
import { useConnection } from '../store/connected';
import { useDataPoints } from '../store/datapoints';
import { useDevices } from '../store/devices';
import { useGroupImages, useGroups } from '../store/groups';
import { useManufacturers } from '../store/manufacturers';
import { useModels } from '../store/models';
import { useInsights, useProjects } from '../store/project';
import { useRulesets } from '../store/rulesets';
import { useSSOStore } from '../store/sso';
import { useTransforms } from '../store/transforms';
import { useUser } from '../store/users';
import ConnectionError from './ConnectionError';
import { useSpinner } from './Spinner';

const RequiredDataCheck: FC = ({ children }) => {
  useDataDisplayUpdater();
  useGroupImages();
  useWebsocket();

  const token = useSSOStore(useCallback(({ token }) => token, []));

  const connected = useConnection(useCallback((state) => state.connected, []));
  const { wrapSpinner, addSpinner, removeSpinner } = useSpinner(
    useCallback(
      (state) => ({
        wrapSpinner: state.wrap,
        addSpinner: state.add,
        removeSpinner: state.remove,
      }),
      [],
    ),
  );

  // DEVICES
  const loadDevices = useDevices(useCallback((state) => state.load, []));
  const { isFetched: devicesFetched, isError: devicesError } = useQuery(
    'devices',
    ({ queryKey }) =>
      wrapSpinner(queryKey[0], api_helpers.fetchDevices().then(loadDevices)),
  );

  // MODELS
  const loadModels = useModels(useCallback((state) => state.load, []));
  const { isFetched: modelsFetched, isError: modelsError } = useQuery(
    'models',
    ({ queryKey }) =>
      wrapSpinner(queryKey[0], api_helpers.fetchModels().then(loadModels)),
  );

  // MANUFACTURERS
  const loadManufacturers = useManufacturers(
    useCallback((state) => state.load, []),
  );
  const { isFetched: manufacturersFetched, isError: manufacturersError } =
    useQuery(
      'manufacturers',
      ({ queryKey }) =>
        wrapSpinner(
          queryKey[0],
          api_helpers.fetchManufacturers().then(loadManufacturers),
        ),
      { enabled: connected },
    );

  // DATA
  const loadDataPoints = useDataPoints(useCallback((state) => state.load, []));
  const { isFetched: dataFetched, isError: dataError } = useQuery(
    'data',
    ({ queryKey }) =>
      wrapSpinner(
        queryKey[0],
        api_helpers.fetchDataForDevices().then(loadDataPoints),
      ),
    { enabled: connected },
  );

  // PROJECTS
  const loadProjects = useProjects(useCallback((state) => state.load, []));
  const { isFetched: projectsFetched, isError: projectsError } = useQuery(
    'projects',
    ({ queryKey }) =>
      wrapSpinner(queryKey[0], api_helpers.fetchProjects().then(loadProjects)),
    { enabled: connected },
  );

  // INSIGHTS
  const loadInsights = useInsights(useCallback((state) => state.load, []));
  const { isFetched: insightsFetched, isError: insightsError } = useQuery(
    'insights',
    ({ queryKey }) =>
      wrapSpinner(queryKey[0], api_helpers.fetchInsights().then(loadInsights)),
    { enabled: connected },
  );

  // RULESETS
  const { loadRulesets, loadRulesetEvents } = useRulesets(
    useCallback(
      (state) => ({
        loadRulesets: state.load,
        loadRulesetEvents: state.loadEvents,
      }),
      [],
    ),
  );
  const { isFetched: rulesetsFetched, isError: rulesetsError } = useQuery(
    'rulesets',
    ({ queryKey }) =>
      wrapSpinner(queryKey[0], api_helpers.fetchRulesets().then(loadRulesets)),
    { enabled: connected },
  );
  const { isFetched: rulesetEventsFetched, isError: rulesetEventsError } =
    useQuery(
      'rulesetEvents',
      ({ queryKey }) =>
        wrapSpinner(
          queryKey[0],
          api_helpers.fetchRulesetEvents().then(loadRulesetEvents),
        ),
      { enabled: connected },
    );

  // TRANSFORMS
  const loadTransforms = useTransforms(useCallback((state) => state.load, []));
  const { isFetched: transformsFetched, isError: transformsError } = useQuery(
    'transforms',
    ({ queryKey }) =>
      wrapSpinner(
        queryKey[0],
        api_helpers.fetchTransforms().then(loadTransforms),
      ),
    { enabled: connected },
  );

  const { ssoUri } = useConfig(
    useCallback(
      (state) => ({
        ssoUri: state.api.sso.api_uri,
      }),
      [],
    ),
  );

  const { loadUsers, setCurrentUser, users } = useUser(
    useCallback(
      (state) => ({
        loadUsers: state.load,
        setCurrentUser: state.setCurrentUser,
        users: state.list,
      }),
      [],
    ),
  );

  useEffect(() => {
    if (!token) {
      return;
    }

    const foundUser = users.find((user) => user.id === token.payload.uid);
    if (!foundUser) {
      return;
    }

    setCurrentUser({
      ...foundUser,
      uid: `${foundUser.id}`,
      admin: token.isAdmin() || false,
    });
  }, [setCurrentUser, token, users]);

  const { isFetched: usersFetched, isError: usersError } = useQuery(
    ['users', ssoUri],
    ({ queryKey }) =>
      wrapSpinner(
        queryKey[0],
        api_helpers.fetchAllUsers(queryKey[1] + '/users').then(loadUsers),
      ),
    { enabled: !!ssoUri },
  );

  const loadGroups = useGroups(useCallback((state) => state.load, []));
  const { isFetched: groupsFetched, isError: groupsError } = useQuery(
    'groups',
    ({ queryKey }) =>
      wrapSpinner(queryKey[0], api_helpers.fetchGroups().then(loadGroups)),
    { enabled: connected },
  );

  const error = useMemo(
    () =>
      devicesError ||
      usersError ||
      manufacturersError ||
      modelsError ||
      dataError ||
      projectsError ||
      insightsError ||
      rulesetsError ||
      rulesetEventsError ||
      groupsError ||
      transformsError,
    [
      dataError,
      devicesError,
      groupsError,
      insightsError,
      manufacturersError,
      modelsError,
      projectsError,
      rulesetEventsError,
      rulesetsError,
      transformsError,
      usersError,
    ],
  );

  const fetched = useMemo(
    () =>
      devicesFetched &&
      usersFetched &&
      manufacturersFetched &&
      modelsFetched &&
      dataFetched &&
      projectsFetched &&
      insightsFetched &&
      rulesetsFetched &&
      rulesetEventsFetched &&
      groupsFetched &&
      transformsFetched,
    [
      devicesFetched,
      usersFetched,
      manufacturersFetched,
      modelsFetched,
      dataFetched,
      projectsFetched,
      insightsFetched,
      rulesetsFetched,
      rulesetEventsFetched,
      groupsFetched,
      transformsFetched,
    ],
  );

  useEffect(() => {
    if (fetched || error) {
      removeSpinner('loading');
    } else {
      addSpinner('loading');
    }
    return () => removeSpinner('loading');
  }, [addSpinner, removeSpinner, fetched, error]);

  if (error) {
    return <ConnectionError onRetry={() => window.location.reload()} />;
  }

  if (!fetched) {
    return null;
  }

  return <>{children}</>;
};

export default RequiredDataCheck;
