import { LoadingButton } from '@mui/lab';
import { Alert, Snackbar, Typography } from '@mui/material';
import { amber } from '@mui/material/colors';
import { DateTime } from 'luxon';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

export interface ReloadWindowDialogProps {
  reloadAt: DateTime;
  onReload: () => void;
}

const ReloadWindowDialog: FC<ReloadWindowDialogProps> = ({
  reloadAt,
  onReload,
}) => {
  const [secondsLeft, setSecondsLeft] = useState(
    Math.round(reloadAt.diffNow().as('seconds')),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setSecondsLeft((prev) => {
        if (prev <= 0) {
          return prev;
        }
        return Math.round(reloadAt.diffNow().as('seconds'));
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [reloadAt, secondsLeft]);

  useEffect(() => {
    if (secondsLeft <= 0) {
      onReload();
    }
  }, [onReload, secondsLeft]);

  const timeLeftString = useMemo(
    () =>
      secondsLeft < 60 ? `${secondsLeft}s` : `${Math.round(secondsLeft / 60)}m`,
    [secondsLeft],
  );

  return (
    <Snackbar open={true}>
      <Alert
        severity="warning"
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& .MuiAlert-action': {
            paddingTop: 0,
            paddingLeft: 2,
            paddingRight: 0,
            paddingBottom: 0,
            marginRight: 0,
          },
          '& .MuiAlert-message': {
            padding: 0,
          },
        }}
        action={
          <LoadingButton
            size="small"
            sx={{ height: 28 }}
            variant="contained"
            onClick={() => setSecondsLeft(0)}
            loading={secondsLeft <= 0}
            color="warning"
          >
            <FormattedMessage
              id="reload_window_dialog.reload_now"
              defaultMessage="Reload now"
            />
          </LoadingButton>
        }
      >
        <Typography color={amber[800]}>
          {secondsLeft > 0 ? (
            <FormattedMessage
              id="reload_window_dialog.update_released_snackbar"
              defaultMessage="Update released. Page will reload automatically in {timeLeft}"
              values={{
                timeLeft: timeLeftString,
              }}
            />
          ) : (
            <FormattedMessage
              id="reload_window_dialog.update_released_snackbar_now"
              defaultMessage="Update released. Page will reload now."
            />
          )}
        </Typography>
      </Alert>
    </Snackbar>
  );
};
export default ReloadWindowDialog;
