import produce from 'immer';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { fromListToLookup, ListAndLookup } from '../helpers/lookup';
import { DeviceManufacturer } from '../models/Device';

interface ManufacturersStore extends ListAndLookup<DeviceManufacturer> {
  load: (models: DeviceManufacturer[]) => void;
}

export const useManufacturers = create<ManufacturersStore>(
  devtools(
    (set) => ({
      list: [],
      lookup: {},
      load: (models) =>
        set(
          produce((state) => {
            state.list = models;
            state.lookup = fromListToLookup(models);
          }),
        ),
    }),
    { name: 'models' },
  ),
);
