import { Email } from '@mui/icons-material';
import {
  Box,
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { BoxProps } from '@mui/material/Box/Box';
import React, { ComponentType } from 'react';
import { FormattedMessage } from 'react-intl';

const ContactUs: ComponentType<BoxProps> = (props) => {
  return (
    <Box {...props} display="flex" flexDirection="column">
      <Box flexGrow={1}>
        <Typography variant="h6">
          <FormattedMessage
            id="contact_us.contact_us"
            defaultMessage="Contact us"
          />
        </Typography>
        <List disablePadding>
          <ListItemButton component="a" href="mailto:support@allbinary.se">
            <ListItemIcon>
              <Email />
            </ListItemIcon>
            <ListItemText
              sx={{ color: ({ palette }) => palette.primary.main }}
              primary="support@allbinary.se"
            />
          </ListItemButton>
        </List>
      </Box>
      <Box style={{ textAlign: 'right' }}>
        <FormattedMessage
          id="contact_us.senseview_is_managed_and_developed by"
          defaultMessage="Senseview is managed and developed by"
        />
        <Button
          sx={{ textTransform: 'none' }}
          variant="text"
          color="primary"
          component="a"
          href="https://allbinary.se"
          target="_blank"
        >
          All Binary
        </Button>
      </Box>
    </Box>
  );
};

export default ContactUs;
