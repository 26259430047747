import { EntityState, Lookup } from '../contexts/DataContext';

export interface WithID {
  id: number | string;
}

export const upsertArray = <T extends WithID>(
  items: T[],
  item: T,
  sortFn?: (a: T, b: T) => number,
): T[] => {
  const result = [...items.filter((i) => i.id !== item.id), item];
  if (sortFn) {
    return result.sort(sortFn);
  }
  return result;
};

export const toLookup = <T extends WithID>(items: T[]): Lookup<T> =>
  items.reduce<Lookup<T>>(
    (acc, item) => ({
      ...acc,
      [item.id]: item,
    }),
    {},
  );

export const generateEntityState = <T extends WithID>(
  items: T[],
): EntityState<T> => ({
  all: items,
  lookup: toLookup(items),
  fetched: true,
  fetching: false,
});

export type ListAndLookup<T extends { id: string }> = {
  list: T[];
  lookup: Record<string, T>;
};

export function fromListToLookup<T extends { id: string }>(
  list: T[],
): Record<string, T> {
  return list.reduce((lookup, item) => {
    lookup[item.id] = item;
    return lookup;
  }, {} as Record<string, T>);
}
