import produce from 'immer';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface ConnectionStore {
  connected: boolean;
  setConnected: (connected: boolean) => void;
}

export const useConnection = create<ConnectionStore>(
  devtools(
    (set) => ({
      connected: false,
      setConnected: (connected: boolean) =>
        set(
          produce((state) => {
            state.connected = connected;
          }),
        ),
    }),
    { name: 'useConnection' },
  ),
);
