import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import SenseViewTheme from './theme/SenseViewTheme';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <SenseViewTheme>
      <App />
    </SenseViewTheme>
  </React.StrictMode>,
);
