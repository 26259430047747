interface Color {
  r: number;
  g: number;
  b: number;
}

export const parseRGB = (color: string): Color => {
  const long = /#(?<r>[0-9a-f]{2})(?<g>[0-9a-f]{2})(?<b>[0-9a-f]{2})$/gi.exec(
    color,
  );
  if (long && long.groups !== undefined) {
    return {
      r: parseInt(long.groups.r, 16),
      g: parseInt(long.groups.g, 16),
      b: parseInt(long.groups.b, 16),
    };
  }

  const short = /#(?<r>[0-9a-f])(?<g>[0-9a-f])(?<b>[0-9a-f])$/gi.exec(color);
  if (short && short.groups !== undefined) {
    return {
      r: parseInt(short.groups.r + short.groups.r, 16),
      g: parseInt(short.groups.g + short.groups.g, 16),
      b: parseInt(short.groups.b + short.groups.b, 16),
    };
  }

  const rgb = /^rgb\(\s*(?<r>\d+),\s*(?<g>\d+)\s*,\s*(?<b>\d+)\s*\)$/gi.exec(
    color,
  );
  if (rgb && rgb.groups !== undefined) {
    return {
      r: parseInt(rgb.groups.r, 10),
      g: parseInt(rgb.groups.g, 10),
      b: parseInt(rgb.groups.b, 10),
    };
  }

  return { r: 0, g: 0, b: 0 };
};

export const luminance = (color: string | undefined): number | null => {
  if (!color) {
    return null;
  }

  const rgb = parseRGB(color);
  return (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
};

export const getFontColor = (backgroundColor: string | undefined): string => {
  const l = luminance(backgroundColor);

  if (!backgroundColor || l === null) {
    return '#000000';
  }

  return l > 190 ? '#000000' : '#ffffff';
};
