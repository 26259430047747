import jwtDecode from 'jwt-decode';
import { DateTime } from 'luxon';

export interface JWTHeader {
  alg: string;
  kid: string;
  typ: string;
}

export interface JWTPayload {
  admin?: boolean;
  email?: string;
  exp?: number;
  first_name?: string;
  iat?: number;
  iss?: string;
  lang?: string;
  last_name?: string;
  login?: boolean;
  nbf?: number;
  org?: string;
  senseview_api?: number;
  sub?: string;
  svc?: string;
  uid?: number;
  username?: string;
}

export class SSOToken {
  header: JWTHeader;
  payload: JWTPayload;
  jwt: string;

  constructor(jwt: string | null) {
    if (!jwt) {
      throw new Error('missing jwt token');
    }
    this.jwt = jwt;
    [this.header, this.payload] = decodeToken(jwt);
  }

  getExpiry(): DateTime | undefined {
    if (!this.payload || !this.payload.exp) {
      return undefined;
    }

    return DateTime.fromSeconds(this.payload.exp);
  }

  getRefreshTimeout(): number {
    const exp = this.getExpiry();
    if (!exp) {
      return 0;
    }

    // return 6000;

    // refresh 5 minutes before expiry
    return exp.minus({ minutes: 5 }).diffNow().as('milliseconds');
  }

  isAdmin(): boolean {
    return (
      this.valid() &&
      !!this.payload.senseview_api &&
      (this.payload.senseview_api & 2) > 0
    );
  }

  valid(): boolean {
    return !!(
      this.payload &&
      this.payload.uid &&
      this.payload.uid >= 0 &&
      this.getExpiry()
    );
  }
}

const decodeToken = (jwt: string): [JWTHeader, JWTPayload] => {
  const header = jwtDecode<JWTHeader>(jwt, { header: true });
  const token = jwtDecode<JWTPayload>(jwt);
  return [header, token];
};
