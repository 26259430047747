import axios from 'axios';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import AxiosConfig from './AxiosConfig';
import { useConfig } from './config';
import { useQuery } from './helpers/hooks';
import { SSOToken } from './models/SSOToken';
import { useSSOStore } from './store/sso';

interface LoginWrapperProps {
  onLocale: (locale: string) => void;
}

const LoginWrapper: FC<LoginWrapperProps> = ({ onLocale, children }) => {
  const query = useQuery();
  const location = useLocation();
  const navigate = useNavigate();

  const config = useConfig(
    useCallback(
      (state) => ({
        serviceName: state.service_name,
        loginUri: state.api.sso.login_uri,
        ssoApi: state.api.sso.api_uri,
        api: state.api.senseview.api_uri,
      }),
      [],
    ),
  );

  const { token, setToken } = useSSOStore();

  const login = useMemo(
    () => () => {
      document.location.href =
        config.loginUri +
        '/login' +
        '?service=' +
        encodeURIComponent(config.serviceName);
    },
    [config.loginUri, config.serviceName],
  );

  useEffect(() => {
    if (!token || !token.valid()) {
      return;
    }

    const refresh = token.getRefreshTimeout();
    if (!refresh) {
      console.error('invalid token, redirecting to login');
      login();
      return;
    }

    // console.log(`refreshing token in ${refresh}ms`, ssoState.token);

    const ref = setTimeout(() => {
      // console.log(`refreshing token now`);
      axios
        .get(`${config.ssoApi}/token?service=${config.serviceName}`)
        .then((res) => {
          if (!res.data.token) {
            throw new Error('invalid credentials');
          }
          return new SSOToken(res.data.token);
        })
        .then(setToken)
        .catch((e) => {
          console.error('invalid token', e);
          login();
        });
    }, refresh);

    return () => {
      clearTimeout(ref);
    };
  }, [config.serviceName, config.ssoApi, login, setToken, token]);

  useEffect(() => {
    const queryToken = query.get('token');
    if (queryToken) {
      try {
        const ssoToken = new SSOToken(queryToken);
        setToken(ssoToken);
        if (ssoToken.payload?.lang) {
          onLocale(ssoToken.payload.lang);
        }
        navigate(location.pathname);
      } catch (e) {
        console.error('failed to parse token', e);
      }
    } else if (!token?.valid()) {
      document.location.href =
        config.loginUri +
        '/login' +
        '?service=' +
        encodeURIComponent(config.serviceName);
    }
  }, [
    token,
    onLocale,
    query,
    location.pathname,
    config.loginUri,
    config.serviceName,
    setToken,
    navigate,
  ]);

  if (!token) {
    return null;
  }

  return <AxiosConfig>{children}</AxiosConfig>;
};

export default LoginWrapper;
