import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { websocketLogin } from '../helpers/api_helpers';
import { SSOToken } from '../models/SSOToken';

const JWT_TOKEN_STORAGE_KEY = 'token';

interface SSOState {
  token?: SSOToken;
  setToken: (token?: SSOToken) => void;
  logout: (ssoUri: string) => void;
}

export const useSSOStore = create<SSOState>(
  devtools(
    (set) => ({
      shouldLogout: false,
      token: localStorage.getItem(JWT_TOKEN_STORAGE_KEY)
        ? new SSOToken(localStorage.getItem(JWT_TOKEN_STORAGE_KEY))
        : undefined,
      setToken: (token) => {
        if (token) {
          localStorage.setItem(JWT_TOKEN_STORAGE_KEY, token.jwt);
          websocketLogin(token.jwt);
        } else {
          localStorage.removeItem(JWT_TOKEN_STORAGE_KEY);
        }
        set({ token });
      },
      logout: (ssoUri) => {
        set({ token: undefined });
        window.location.href = ssoUri + '/logout';
      },
    }),
    { name: 'sso' },
  ),
);
