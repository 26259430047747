import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import RequiredDataCheck from './components/RequiredDataCheck';
import LoginWrapper from './LoginWrapper';
import SentryRoutes from './SentryRoutes';
import MainView from './views/MainView';
import PublicDeviceView from './views/PublicDeviceView';

interface Props {
  onLocale: (l: string) => void;
  locale: string;
}

export const Router: React.FC<Props> = ({ locale, onLocale }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="sv-SE">
      <BrowserRouter>
        <SentryRoutes>
          <Route path="/public/devices/:id" element={<PublicDeviceView />} />
          <Route
            path="*"
            element={
              <LoginWrapper onLocale={onLocale}>
                <RequiredDataCheck>
                  <MainView locale={locale} setLocale={onLocale} />
                </RequiredDataCheck>
              </LoginWrapper>
            }
          />
        </SentryRoutes>
      </BrowserRouter>
    </LocalizationProvider>
  );
};

export default Router;
