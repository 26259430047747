import BatteryLevel from '../images/icon_battery-level.svg';
import IconBoolean from '../images/icon_boolean.svg';
import IconCo2 from '../images/icon_co2.svg';
import IconDistance from '../images/icon_distance.svg';
import IconEvaporation from '../images/icon_evaporation.svg';
import IconHumidity from '../images/icon_humidity.svg';
import IconLighting from '../images/icon_lighting.svg';
import IconMotionSensor from '../images/icon_motion-sensor.svg';
import IconParking from '../images/icon_parking.svg';
import IconPercent from '../images/icon_percent.svg';
import IconPressure from '../images/icon_pressure.svg';
import IconRadio from '../images/icon_radio.svg';
import IconSound from '../images/icon_sound.svg';
import IconSun from '../images/icon_sun.svg';
import IconTemperature from '../images/icon_temperature.svg';
import IconWater from '../images/icon_water.svg';
import IconWaterLeak from '../images/icon_water-leak.svg';
import IconWind from '../images/icon_wind.svg';

export function getIcon(id: string, sensorType?: SensorType): string {
  switch (id.toLowerCase()) {
    case 'battery':
      return BatteryLevel;
    case 'battery-level':
      return BatteryLevel;
    case 'temperature':
      return IconTemperature;
    case 'humidity':
    case 'outside-humidity':
      return IconHumidity;
    case 'co2':
      return IconCo2;
    case 'boolean':
      return IconBoolean;
    case 'distance':
      return IconDistance;
    case 'pressure':
      return IconPressure;
    case 'parking':
      return IconParking;
    case 'lighting':
      return IconLighting;
    case 'sound':
      return IconSound;
    case 'sound-average':
      return IconSound;
    case 'sound-peak':
      return IconSound;
    case 'water-leak':
      return IconWaterLeak;
    case 'motion-sensor':
      return IconMotionSensor;
    case 'wind-speed':
    case 'wind-direction':
    case 'avg-wind-speed':
      return IconWind;
    case 'solar-radiation':
      return IconSun;
    case 'day-rain':
    case 'rain-rate':
      return IconWater;
    case 'day-et':
      return IconEvaporation;
    case 'global-rssi':
    case 'global-snr':
      return IconRadio;
  }

  if (sensorType) {
    switch (sensorType) {
      case 'temperature':
        return IconTemperature;
      case 'boolean':
        return IconBoolean;
      case 'acceleration':
        return IconMotionSensor;
      case 'distance':
        return IconDistance;
      case 'pressure':
        return IconPressure;
      case 'co2':
        return IconCo2;
      case 'gps':
        return IconMotionSensor;
      case 'humidity':
        return IconHumidity;
      case 'light':
        return IconLighting;
      case 'percent':
        return IconPercent;
    }
  }

  return IconMotionSensor;
}
