import './fonts.css';

import { CssBaseline, GlobalStyles, ThemeOptions } from '@mui/material';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import React, { FunctionComponent } from 'react';

declare module '@mui/material/styles' {
  interface Theme {
    appBarHeight: React.CSSProperties['height'];
    drawerPanel: {
      mini: {
        closedWidth: number;
      };
      leftPanelWidth: React.CSSProperties['width'];
      rightPanelWidth: React.CSSProperties['width'];
    };
    shapes: {
      borderRadius: {
        normal: number;
        large: number;
      };
    };
  }

  interface TypeText {
    hint: string;
  }

  interface ThemeOptions {
    appBarHeight: React.CSSProperties['height'];
    drawerPanel: {
      mini: {
        closedWidth: number;
      };
      leftPanelWidth: React.CSSProperties['width'];
      rightPanelWidth: React.CSSProperties['width'];
    };
    shapes: {
      borderRadius: {
        normal: number;
        large: number;
      };
    };
  }

  interface Palette {
    accent: Palette['primary'];
    accentVariant: Palette['primary'];
    tags: string[];
  }

  interface PaletteOptions {
    accent: PaletteOptions['primary'];
    accentVariant: PaletteOptions['primary'];
    tags: string[];
  }

  // interface PaletteColor {
  //   darker?: string;
  // }
  // interface SimplePaletteColorOptions {
  //   darker?: string;
  // }
  // interface ThemeOptions {
  //   status: {
  //     danger: React.CSSProperties['color'];
  //   };
  // }
}

let muiTheme = createTheme({
  palette: {
    primary: {
      light: '#6AA1F9',
      main: '#206AF5',
      dark: '#164ACD',
      contrastText: '#fff',
    },
    secondary: {
      light: '#DF7B4A',
      main: '#CA4014',
      dark: '#95260C',
      contrastText: '#fff',
    },
    accent: {
      light: '#344564',
      main: '#1F3053',
      dark: '#19264A',
      contrastText: '#fff',
    },
    accentVariant: {
      main: '#a5adbb',
    },
    background: {
      default: '#E7ECF0',
      paper: '#fff',
    },
    text: {
      primary: '#000000',
      secondary: '#747373',
      hint: '#686b73',
    },
    tags: [
      '#D44D51',
      '#61B820',
      '#674CB3',
      '#D6BA29',
      '#4BABEF',
      '#3CA183',
      '#A33EE1',
      '#ED853A',
      '#1F62CD',
      '#248734',
      '#DD74DF',
      '#956329',
    ],
  },
  typography: {
    fontFamily: '"Fira Sans", "Arial", sans-serif !important',
    htmlFontSize: 16,
    fontSize: 12,
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
    },
    h6: {
      fontSize: '1.1rem !important',
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.75,
      letterSpacing: '0.00938em',
    },
    subtitle2: {
      fontSize: '0.875rem !important',
      fontWeight: 500,
      lineHeight: 1.57,
      letterSpacing: '0.00714em',
    },
    overline: {
      fontSize: '0.75rem !important',
      fontWeight: 400,
      lineHeight: 2.66,
      textTransform: 'uppercase',
      letterSpacing: '0.08333em',
    },
    caption: {
      fontSize: '0.75rem !important',
      letterSpacing: '0.03333em',
    },
  },
  appBarHeight: 56,

  drawerPanel: {
    mini: {
      closedWidth: 56,
    },
    leftPanelWidth: '340px',
    rightPanelWidth: '540px',
  },
  shapes: {
    borderRadius: {
      normal: 28,
      large: 48,
    },
  },
  shape: {
    borderRadius: 8,
  },
});

muiTheme = createTheme(muiTheme, {
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.8rem',
          fontWeight: 'normal',
          borderRadius: 2,
        },
      },
      defaultProps: {
        arrow: true,
      },
    },
    MuiMenu: {
      defaultProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      },
    },

    MuiListItem: {
      styleOverrides: {
        root: {
          '&$selected': {
            // color: `${muiTheme.palette.primary.contrastText} !important`,
            backgroundColor: `${muiTheme.palette.action.selected} !important`,
          },
        },
        button: {
          '&:hover': {
            backgroundColor: `${muiTheme.palette.action.hover} !important`,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        filled: {
          backgroundColor: muiTheme.palette.background.paper,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: muiTheme.typography.h6,
      },
    },

    MuiButton: {
      defaultProps: {
        variant: 'contained',
        color: 'inherit',
      },
      styleOverrides: {
        outlined: {
          height: 40,
          borderRadius: 20,
          borderColor: muiTheme.palette.accentVariant.main,
        },
        outlinedSizeLarge: {
          height: 48,
          borderRadius: 24,
        },
        outlinedSizeSmall: {
          height: 32,
          borderRadius: 16,
        },

        contained: {
          height: 40,
          borderRadius: 20,
          textTransform: 'none',
          padding: muiTheme.spacing(1, 3),
        },
        containedSizeLarge: {
          height: 48,
          borderRadius: 24,
        },
        containedSizeSmall: {
          height: 32,
          borderRadius: 16,
        },
      },
    },

    MuiButtonGroup: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          borderRadius: 20,
        },
        groupedContainedPrimary: {
          borderColor: '#bdbdbd !important',
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          overflow: 'visible !important',
        },
        indicator: { width: '100%', borderRadius: 24, display: 'none' },
        scroller: {
          overflow: 'visible !important',
        },
        flexContainer: {
          [muiTheme.breakpoints.down('md')]: {
            flexDirection: 'row',
            '& > *': {
              marginRight: muiTheme.spacing(2),
            },
            '& > *:last-child': {
              marginRight: 0,
            },
          },
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: muiTheme.spacing(2),
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '8px 32px',
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '8px 32px',
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          [muiTheme.breakpoints.down('md')]: {
            minWidth: 'auto',
          },
          minWidth: 160,
          borderRadius: 24,
          textTransform: 'none',
          height: 48,
          marginBottom: 8,
          background: `linear-gradient(to right, ${muiTheme.palette.primary.main} 50%, ${muiTheme.palette.accentVariant.main} 50%)`,
          backgroundSize: '200% 100%',
          backgroundPosition: 'right bottom',
          color: muiTheme.palette.text.hint,
          marginRight: muiTheme.spacing(2),
          transition: muiTheme.transitions.create(
            ['background', 'color', 'box-shadow'],
            {
              easing: muiTheme.transitions.easing.easeInOut,
              duration: muiTheme.transitions.duration.standard,
            },
          ),
          boxShadow: 'none',
          '&::before': {
            height: 48,
            borderRadius: 24,
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            opacity: 1,
            transition: muiTheme.transitions.create('width', {
              easing: muiTheme.transitions.easing.easeInOut,
              duration: muiTheme.transitions.duration.standard,
            }),
          },
          '&.Mui-selected': {
            boxShadow: muiTheme.shadows[6],
            color: 'white !important',
            backgroundPosition: 'left bottom',
            '&::before': {
              width: '100%',
            },
          },
        },
        wrapped: {
          zIndex: 9999,
          textTransform: 'capitalize',
        },
        textColorInherit: {
          opacity: 1,
        },
      },
    },

    // MuiSpeedDial: {
    //   styleOverrides: {
    //     fab: {
    //       borderRadius: (props: any) => getBorderRadius(props.corners),
    //     },
    //   },
    // },

    MuiDrawer: {
      styleOverrides: {
        paper: { borderRight: 'none' },
      },
    },

    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
    },
  },
} as Partial<ThemeOptions>);

const SenseViewTheme: FunctionComponent<any> = (props: any) => {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            '*::-webkit-scrollbar': {
              width: '0.7em',
            },
            '*::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
            '*::-webkit-scrollbar-thumb': {
              backgroundColor: '#96aab2',
              borderRadius: '0.7em',
              // border: '2px solid transparent',
              backgroundClip: 'content-box',
              // boxShadow: '1px 1px 5px rgba(0,0,0,.4)',
            },
            '*::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#5c757e',
            },
          }}
        />
        {props.children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};

export { muiTheme };
export default SenseViewTheme;
