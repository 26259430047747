import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';

import { ServerDevice } from '../../models/Device';
import Widgets, {
  makeWidgetItems,
  WidgetItem,
} from '../DeviceInformation/Widgets';

interface PublicDeviceProps {
  device: ServerDevice;
}

const PublicDevice: React.FC<PublicDeviceProps> = ({ device }) => {
  const widgets = useMemo<WidgetItem[]>(() => {
    return makeWidgetItems(device);
  }, [device]);

  return (
    <Box
      bgcolor="accent.main"
      flexGrow={1}
      minHeight="100vh"
      px={4}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box color="white" py={4} px={2}>
        <Typography variant="h5" color="inherit">
          {device.name}
        </Typography>
      </Box>
      <Box
        flexGrow={1}
        width="100%"
        p={4}
        sx={{
          borderRadius: ({ shapes }) =>
            `${shapes.borderRadius.normal}px ${shapes.borderRadius.normal}px 0 0`,
          bgcolor: 'background.default',
        }}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Widgets widgets={widgets} responsive={true} />
      </Box>
    </Box>
  );
};

export default PublicDevice;
