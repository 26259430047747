import { DateTime } from 'luxon';

export type Reviver = (key: string, value: any) => any;

export const entityReviver: Reviver = (key, value) => {
  if (
    value !== null &&
    [
      'created_at',
      'updated_at',
      'cleared_at',
      'time',
      'last_seen',
      'active_from',
      'active_to',
      'start_time',
      'end_time',
    ].includes(key)
  ) {
    return DateTime.fromISO(value);
  }
  return value;
};
