import produce from 'immer';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { Ruleset, RulesetEvent } from '../models/Ruleset';

interface RulesetsStore {
  list: Ruleset[];
  lookup: Record<number, Ruleset>;
  load: (rulesets: Ruleset[]) => void;
  setRuleset: (ruleset: Ruleset) => void;
  deleteRuleset: (id: number) => void;
  events: RulesetEvent[];
  loadEvents: (events: RulesetEvent[]) => void;
  setEvent: (event: RulesetEvent) => void;
  clearEvent: (event: RulesetEvent) => void;
}

const eventEqual: (a: RulesetEvent, b: RulesetEvent) => boolean = (a, b) => {
  if (a.ruleset_id !== b.ruleset_id) {
    return false;
  }

  if (a.device_id !== b.device_id) {
    return false;
  }

  if (a.model_id !== b.model_id) {
    return false;
  }
  if (a.sensor_id !== b.sensor_id) {
    return false;
  }
  return a.time.equals(b.time);
};

export const useRulesets = create<RulesetsStore>(
  devtools(
    (set) => ({
      list: [],
      lookup: {},
      events: [],
      load: (rulesets) =>
        set(
          produce((state) => {
            state.list = rulesets;
            state.lookup = rulesets.reduce<Record<number, Ruleset>>(
              (acc, ruleset) => {
                acc[ruleset.id] = ruleset;
                return acc;
              },
              {},
            );
          }),
        ),
      setRuleset: (ruleset) =>
        set(
          produce((state: RulesetsStore) => {
            if (state.lookup[ruleset.id] === undefined) {
              state.list.push(ruleset);
              state.lookup[ruleset.id] = ruleset;
              return;
            }
            const i = state.list.findIndex(
              (p) => p.id === ruleset.id && p.updated_at < ruleset.updated_at,
            );
            if (i === -1) {
              return;
            }

            state.list[i] = ruleset;
            state.lookup[ruleset.id] = ruleset;
          }),
        ),
      deleteRuleset: (id) =>
        set(
          produce((state: RulesetsStore) => {
            if (state.lookup[id] === undefined) {
              return;
            }

            const i = state.list.findIndex((p) => p.id === id);
            if (i === -1) {
              return;
            }

            state.list.splice(i, 1);
            delete state.lookup[id];
          }),
        ),
      loadEvents: (events) =>
        set(
          produce((state) => {
            state.events = events;
          }),
        ),
      setEvent: (event) =>
        set(
          produce((state: RulesetsStore) => {
            const i = state.events.findIndex((e) => eventEqual(e, event));
            if (i === -1) {
              state.events.push(event);
              return;
            }

            state.events[i] = event;
          }),
        ),
      clearEvent: (event) =>
        set(
          produce((state: RulesetsStore) => {
            const i = state.events.findIndex((e) => eventEqual(e, event));
            if (i === -1) {
              return;
            }

            state.events.splice(i, 1);
          }),
        ),
    }),
    { name: 'useRulesets' },
  ),
);
