import React from 'react';

import { useSentry } from './sentry';

export const SentryProvider: React.FC = ({ children }) => {
  const { loaded } = useSentry();

  if (!loaded) {
    return null;
  }
  return <>{children}</>;
};

export default SentryProvider;
