import { CircularProgress, Paper, Typography } from '@mui/material';
import axios from 'axios';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import PublicDevice from '../components/public/PublicDevice';
import { useConfig } from '../config';
import { useDataDisplayUpdater } from '../hooks/useDataDisplay';
import { ServerDeviceData } from '../models/DataPoint';
import {
  DeviceManufacturer,
  DeviceModel,
  ServerDevice,
} from '../models/Device';
import { useDataPoints } from '../store/datapoints';
import { useDevices } from '../store/devices';
import { useManufacturers } from '../store/manufacturers';
import { useModels } from '../store/models';
import { useTransforms } from '../store/transforms';

export interface PublicDeviceData {
  device: ServerDevice;
  model: DeviceModel;
  manufacturer: DeviceManufacturer;
  data: ServerDeviceData[];
  transforms: Transform[];
}

const PublicDeviceView: FC = () => {
  const params = useParams<{ id?: string }>();
  const [ws, setWs] = useState<WebSocket | undefined>();
  const apiUri = useConfig(
    useCallback((state) => state.api.senseview.api_uri, []),
  );

  useDataDisplayUpdater();
  const { device, setDevice, removeDevice } = useDevices(
    useCallback(
      ({ lookup, setDevice, removeDevice }) => ({
        device: params.id ? lookup[params.id] : undefined,
        setDevice,
        removeDevice,
      }),
      [params.id],
    ),
  );

  const { loadDataPoints, addDataPoint } = useDataPoints(
    useCallback(
      ({ load, add }) => ({ loadDataPoints: load, addDataPoint: add }),
      [],
    ),
  );

  const loadModels = useModels(useCallback(({ load }) => load, []));
  const loadManufacturers = useManufacturers(
    useCallback(({ load }) => load, []),
  );
  const loadTransforms = useTransforms(useCallback(({ load }) => load, []));

  const {
    data: deviceData,
    refetch,
    isFetching,
  } = useQuery(['device', params.id], () =>
    axios
      .get<PublicDeviceData>(`${apiUri}/public/devices/${params.id}`)
      .then((response) => response.data),
  );

  useEffect(() => {
    if (deviceData?.device) {
      setDevice(deviceData.device);
    } else if (params.id) {
      removeDevice(params.id);
    }

    loadModels(deviceData?.model ? [deviceData.model] : []);
    loadManufacturers(
      deviceData?.manufacturer ? [deviceData.manufacturer] : [],
    );
    loadTransforms(deviceData?.transforms || []);
  }, [
    deviceData?.device,
    deviceData?.model,
    deviceData?.manufacturer,
    deviceData?.transforms,
    params.id,
    removeDevice,
    setDevice,
    loadModels,
    loadManufacturers,
    loadTransforms,
  ]);

  useEffect(() => {
    loadDataPoints(deviceData?.data || []);
  }, [deviceData?.data, loadDataPoints]);

  const connect = useCallback(() => {
    if (ws && ws.readyState !== WebSocket.CLOSED) {
      return;
    }

    const uri = apiUri.replace('http', 'ws') + '/ws';
    const localWs = new WebSocket(uri);
    localWs.onopen = () => {
      void refetch();
    };

    localWs.onmessage = (e: MessageEvent) => {
      const ev = JSON.parse(e.data);
      switch (ev.type) {
        case 'DEVICE_UPDATE': {
          setDevice(ev.message as ServerDevice);
          break;
        }
        case 'DATAPOINT_ADD': {
          addDataPoint(ev.message as ServerDeviceData);
          break;
        }
        default:
          break;
      }
    };

    localWs.onclose = () => {
      // console.log('disconnected', ev.code, ev.reason);
      setTimeout(connect, 3000);
    };

    setWs(localWs);
  }, [addDataPoint, apiUri, refetch, setDevice, ws]);
  //state.device, state.displayValues, fetch, match.params.id, ws
  useEffect(() => {
    if (params.id) {
      connect();
    }
  }, [params.id, connect]);

  if (isFetching || !ws) {
    return (
      <Paper sx={{ m: 2, p: 2 }}>
        <CircularProgress />
      </Paper>
    );
  }

  if (!device) {
    return (
      <Paper sx={{ m: 2, p: 2 }}>
        <Typography variant="h6">
          <FormattedMessage
            id="public_device_view.device_not_found"
            defaultMessage="Device not found"
          />
        </Typography>
      </Paper>
    );
  }

  return <PublicDevice device={device} />;
};

export default PublicDeviceView;
