import { Settings, ShowChart } from '@mui/icons-material';
import {
  Box,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

import { ServerDevice } from '../../models/Device';
import { useModels } from '../../store/models';
import { getIcon } from '../Icons';
import Widget from './Widget';

export class WidgetItem {
  deviceId: string;
  sensorId: string;
  icon: string;
  title: string;
  primary: boolean;

  constructor(device: ServerDevice, sensorId: string, title?: string) {
    const sensorType =
      useModels.getState().lookup[device.model_id]?.sensors[sensorId]?.type;

    this.deviceId = device.id;
    this.sensorId = sensorId;
    this.icon = getIcon(sensorId, sensorType);
    this.title = title || device.meta.sensors[sensorId]?.name || sensorId;
    this.primary = device.meta.primary_sensor === sensorId;
  }
}

export const makeWidgetItems = (device: ServerDevice): WidgetItem[] => {
  return Object.keys(device.meta.sensors)
    .map((id) => new WidgetItem(device, id))
    .sort((a, b) => {
      if (a.primary) {
        return -1;
      }
      if (b.primary) {
        return 1;
      }

      return a.title.localeCompare(b.title);
    });
};

export interface WidgetsProps {
  widgets: WidgetItem[];
  responsive?: boolean;
  onGraphOpen?: (device_id: string, sensor_id: string) => void;
  onSettingsOpen?: (device_id: string, sensor_id: string) => void;
}

interface SensorRef {
  deviceId: string;
  sensorId: string;
}

const Widgets: FunctionComponent<WidgetsProps> = ({
  widgets,
  onGraphOpen,
  onSettingsOpen,
}: WidgetsProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
  const [selectedSensor, setSelectedSensor] = useState<SensorRef>();

  const clickable = useMemo(
    () => !!onGraphOpen || !!onSettingsOpen,
    [onGraphOpen, onSettingsOpen],
  );

  const handleClick = useCallback(
    (
      event: React.MouseEvent<HTMLElement>,
      deviceId: string,
      sensorId: string,
    ) => {
      event.preventDefault();
      event.stopPropagation();
      setSelectedSensor({ deviceId, sensorId });
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleGraphOpen = useCallback(() => {
    if (onGraphOpen && selectedSensor) {
      onGraphOpen(selectedSensor.deviceId, selectedSensor.sensorId);
    }
    setAnchorEl(undefined);
    setSelectedSensor(undefined);
  }, [onGraphOpen, selectedSensor]);

  const handleSettingsOpen = useCallback(() => {
    if (onSettingsOpen && selectedSensor) {
      onSettingsOpen(selectedSensor.deviceId, selectedSensor.sensorId);
    }
    setAnchorEl(undefined);
    setSelectedSensor(undefined);
  }, [onSettingsOpen, selectedSensor]);

  return (
    <>
      <Menu
        open={!!anchorEl}
        onClose={() => setAnchorEl(undefined)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {onGraphOpen && (
          <MenuItem onClick={handleGraphOpen}>
            <ListItemIcon>
              <ShowChart />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage
                id="widgets.graph"
                defaultMessage="Data Graph"
              />
            </ListItemText>
          </MenuItem>
        )}
        {onSettingsOpen && (
          <MenuItem onClick={handleSettingsOpen}>
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage
                id="widgets.settings"
                defaultMessage="Settings"
              />
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
      {widgets.length > 0 ? (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          {widgets.map((widget, i) => (
            <Grid
              key={'widget-' + i}
              item
              xs={6}
              sm={4}
              justifyContent="center"
              display="flex"
            >
              <Widget
                deviceId={widget.deviceId}
                sensorId={widget.sensorId}
                onClick={
                  clickable
                    ? (event) =>
                        handleClick(event, widget.deviceId, widget.sensorId)
                    : undefined
                }
                primary={widget.primary}
                icon={widget.icon}
                name={widget.title}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box flexGrow={1} alignItems="center" justifyContent="center">
          <Typography variant="h6">
            <FormattedMessage
              id="rightpanel_widgetlist.no_values"
              defaultMessage="No values to show"
            />
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Widgets;
