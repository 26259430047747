import produce from 'immer';
import { DateTime } from 'luxon';
import { useCallback, useEffect } from 'react';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { getRemap } from '../helpers/device_helpers';
import { ServerDeviceData } from '../models/DataPoint';
import { DeviceModel, ServerDevice } from '../models/Device';
import { useDataPoints } from '../store/datapoints';
import { useDevices } from '../store/devices';
import { useModels } from '../store/models';
import { useTransforms } from '../store/transforms';

interface DataDisplayStore {
  deviceColors: Record<string, Record<string, string>>;
  lastSeen: Record<string, DateTime>;
  update: (
    devices: ServerDevice[],
    modelLookup: Record<string, DeviceModel>,
    transforms: Transform[],
    dataPoints: Record<string, Record<string, ServerDeviceData>>,
  ) => void;
}

export const useDataDisplay = create<DataDisplayStore>(
  devtools(
    (set) => ({
      deviceColors: {},
      lastSeen: {},
      update: (devices, modelLookup, transforms, dataPoints) => {
        set(
          produce((state) => {
            devices.forEach((d) => {
              if (!state.deviceColors[d.id]) {
                state.deviceColors[d.id] = {};
              }

              Object.keys(d.meta.sensors).forEach((sensorId) => {
                const dataPoint =
                  dataPoints[d.id] && dataPoints[d.id][sensorId];
                if (
                  dataPoint &&
                  (!state.lastSeen[d.id] ||
                    state.lastSeen[d.id] < dataPoint.time)
                ) {
                  state.lastSeen[d.id] = dataPoint.time;
                }

                if (
                  !d.meta.sensors[sensorId].remap ||
                  !dataPoints[d.id] ||
                  !dataPoints[d.id][sensorId]
                ) {
                  // Missing remaps or data points, set no color and return
                  if (state.deviceColors[d.id][sensorId] !== undefined) {
                    state.deviceColors[d.id][sensorId] = undefined;
                  }
                  return;
                }

                const remap = getRemap(
                  d.meta.sensors[sensorId].remap,
                  dataPoint.value,
                );

                if (!remap || !remap.color) {
                  // No active remap or no color, set no color and return
                  if (state.deviceColors[d.id][sensorId] !== undefined) {
                    state.deviceColors[d.id][sensorId] = undefined;
                  }
                  return;
                }

                if (state.deviceColors[d.id][sensorId] !== remap.color) {
                  state.deviceColors[d.id][sensorId] = remap.color;
                }
              });
            });
          }),
        );
      },
    }),
    { name: 'data-display' },
  ),
);

export const useDataDisplayUpdater = () => {
  const { deviceList } = useDevices(
    useCallback(
      (state) => ({
        deviceList: state.list,
        setDevice: state.setDevice,
        removeDevice: state.removeDevice,
      }),
      [],
    ),
  );
  const modelLookup = useModels(useCallback(({ lookup }) => lookup, []));
  const transforms = useTransforms(
    useCallback(({ transforms }) => transforms, []),
  );
  const { dataPoints } = useDataPoints(
    useCallback(
      (state) => ({
        dataPoints: state.data,
        addDataPoint: state.add,
      }),
      [],
    ),
  );
  const updateDataDisplay = useDataDisplay(
    useCallback(({ update }) => update, []),
  );
  useEffect(() => {
    updateDataDisplay(deviceList, modelLookup, transforms, dataPoints);
  }, [dataPoints, deviceList, modelLookup, transforms, updateDataDisplay]);

  // useEffect(() => {
  //   const lookup: Record<string, DateTime | undefined> = {};
  //   Object.keys(dataPoints).forEach((device) => {
  //     lookup[device] = undefined;
  //     Object.keys(dataPoints[device]).forEach((sensor) => {
  //       const data = dataPoints[device][sensor];
  //       if (!lookup[device] || data.time > lookup[device]) {
  //         lookup[device] = data.time;
  //       }
  //     });
  //   });
  //   updateLastSeen(lookup);
  // }, [dataPoints, updateLastSeen]);
};
