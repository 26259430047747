import axios from 'axios';

interface VersionResponse {
  data: {
    version: string;
  };
}

export let currentVersion: string | null = null;
let checkTimer = null as number | null;

function getLatestVersion(): Promise<string | null> {
  const url =
    window.location.protocol +
    '//' +
    window.location.hostname +
    ':' +
    window.location.port +
    '/version.json';
  return axios({
    url,
    method: 'GET',
    headers: { 'Cache-Control': 'no-cache' },
  })
    .then((res: VersionResponse) => {
      if (Object.prototype.hasOwnProperty.call(res.data, 'version')) {
        return res.data.version;
      }
      return null;
    })
    .catch(() => {
      return null;
    });
}

function checkLatestVersion(cb: (() => void) | null): void {
  // console.log('checking');
  void getLatestVersion().then((latestVersion) => {
    if (!currentVersion) {
      // First time we do not have a current_version, set it.
      currentVersion = latestVersion;
    } else if (latestVersion && currentVersion !== latestVersion) {
      // Version has changed!
      if (cb) {
        cb();
      } else {
        window.location.reload();
      }
    }
  });
}

/** updateCheck defaults to reloading root if no callback is specified. */
function updateCheck(
  checkFrequency: number,
  cb: (() => void) | null = null,
): void {
  checkLatestVersion(cb);

  if (checkTimer) {
    clearTimeout(checkTimer);
  }

  checkTimer = window.setTimeout(() => {
    updateCheck(checkFrequency, cb);
  }, checkFrequency);
}

export default { updateCheck, current_version: currentVersion };
