import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { version } from '../../../package.json';
import { useConfig } from '../../config';
import { useSSOStore } from '../../store/sso';
import { useUser } from '../../store/users';
import ContactUs from './ContactUs';
import LocalePicker from './LocalePicker';

interface AllbinAppDrawerProps {
  open: boolean;
  onClose: () => void;
  locale: string;
  onLocale: (locale: string) => void;
}

const AllbinAppDrawer: FC<AllbinAppDrawerProps> = ({
  open,
  onClose,
  locale,
  onLocale,
}) => {
  const currentUser = useUser(useCallback((state) => state.currentUser, []));

  const ssoUri = useConfig(useCallback((state) => state.api.sso.login_uri, []));
  const logout = useSSOStore(useCallback(({ logout }) => logout, []));

  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: ({ palette }) => palette.background.default,
          // color: ({ palette }) => palette.primary.main,
          width: '400px',
          paddingTop: 2,
        },
      }}
    >
      <Toolbar>
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          alignItems="center"
        >
          <Box flexGrow={1}>
            <Typography variant="h4">SenseView {version}</Typography>
          </Box>
          <Box>
            <IconButton onClick={onClose} size="large">
              <Close />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
      <Stack direction="column" height="100%" spacing={2} p={2}>
        <LocalePicker locale={locale} onLocale={onLocale} />
        <ContactUs flexGrow={1} />
      </Stack>
      <Stack
        direction="row"
        sx={{
          backgroundColor: ({ palette }) => palette.accent.main,
        }}
        p={2}
        alignItems="center"
      >
        <Stack flexGrow={1} sx={{ color: 'white' }}>
          <Typography variant="subtitle1">
            <FormattedMessage
              id="allbin_app_drawer.logged_in_as"
              defaultMessage="Logged in as"
            />
          </Typography>
          <Typography>
            {currentUser?.username || currentUser?.id || '-'}
          </Typography>
        </Stack>
        <Button
          variant="outlined"
          sx={{ color: 'white', textTransform: 'none' }}
          onClick={() => logout(ssoUri)}
        >
          <FormattedMessage
            id="allbin_app_drawer.logout"
            defaultMessage="Logout"
          />
        </Button>
      </Stack>
    </Drawer>
  );
};

export default AllbinAppDrawer;
