import axios, { AxiosRequestConfig } from 'axios';

import { Lookup } from '../contexts/DataContext';
import * as device_helpers from '../helpers/device_helpers';
import { ServerDeviceData } from '../models/DataPoint';
import {
  Device,
  DeviceModel,
  DeviceUpdate,
  ServerDevice,
} from '../models/Device';
import { Insight, NewInsight } from '../models/Insight';
import { NewProject, Project } from '../models/Project';
import { entityReviver, Reviver } from '../models/Reviver';
import { Ruleset, RulesetEvent } from '../models/Ruleset';
import { useDataPoints } from '../store/datapoints';
import { useDevices } from '../store/devices';
import { useManufacturers } from '../store/manufacturers';
import { useModels } from '../store/models';
import { useSSOStore } from '../store/sso';

// TODO: Rewrite for fetch() api?
type Method = 'GET' | 'DELETE' | 'POST' | 'PUT';

let ws: WebSocket | null = null;

function apiCall<T>(
  url: string,
  method: Method = 'GET',
  data: any = null,
  reviver: Reviver | undefined = entityReviver,
): Promise<T> {
  // const stack = new Error().stack;
  const jwtToken = useSSOStore.getState().token;
  const req: AxiosRequestConfig = {
    url,
    method,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
    transformResponse: reviver
      ? (data) => {
          return JSON.parse(data, reviver);
        }
      : undefined,
  };
  if (data) {
    req.data = data;
  }
  return axios(req).then((res) => {
    return res.data as T;
  });
  // .catch(err => {
  //   if (err.response.status === 401) {
  //     document.location.href =
  //       config.api.sso.login_uri +
  //       '/login' +
  //       '?service=' +
  //       encodeURIComponent(config.service_name);
  //   }
  //   err.stack = stack;
  //   err.network_error = true;
  //   return reject(err);
  // });
}

export const websocketLogin = (token: string): void => {
  if (ws !== null) {
    const message = JSON.stringify({
      type: 'LOGIN',
      payload: token,
    });
    if (ws.readyState === ws.OPEN) {
      ws.send(`${message}`);
    } else {
      ws.close();
    }
  }
};

export interface WebsocketEvent<MessageType> {
  type: string;
  message: MessageType;
}

export function getEventMessage<T>(event: WebsocketEvent<T>): T {
  return event.message;
}

const encodedImageRegExp = RegExp(
  /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,(.*)/,
);

const apiFuncs = {
  fetchAllUsers: (url: string): Promise<UsersGetResponse> => {
    return apiCall<UsersGetResponse>(url);
  },

  fetchDevices: (): Promise<ServerDevice[]> =>
    apiCall<DeviceGetResponse>('/devices', 'GET'),

  fetchMyDevices: (modelLookup: Lookup<DeviceModel>): Promise<Device[]> => {
    return apiCall<DeviceGetResponse>('/devices', 'GET').then(
      (serverDevices) => {
        useDevices.getState().load([...serverDevices]);

        return serverDevices.map((serverDevice) => {
          return device_helpers.unpack(
            serverDevice,
            modelLookup[serverDevice.model_id],
          );
        });
      },
    );
  },
  fetchOrphans: (): Promise<Array<{ id: string }>> => {
    return apiCall<Array<{ id: string }>>('/orphans', 'GET');
  },
  fetchDeviceSensorData: (
    deviceId: string,
    sensorId: string,
    from: string,
    to: string,
    downsample: number,
  ): Promise<ServerDeviceData[]> => {
    return apiCall<ServerDeviceData[]>(
      `/devices/${deviceId}/data/${sensorId}?from=${from}&to=${to}&downsample=${downsample}`,
      'GET',
    );
  },
  fetchDataForDevices: (): Promise<ServerDeviceData[]> => {
    return apiCall<ServerDeviceData[]>('/data', 'GET', null).then((result) => {
      useDataPoints.getState().load(result);
      return result;
    });
  },
  updateDevice: (device: Device, model: DeviceModel): Promise<Device> => {
    return apiCall<ServerDevice>(
      '/devices',
      'PUT',
      device_helpers.pack(device),
    ).then((serverDevice) => {
      return device_helpers.unpack(serverDevice, model);
    });
  },

  updateServerDevice: (device: ServerDevice): Promise<ServerDevice> => {
    return apiCall<ServerDevice>('/devices', 'PUT', device);
  },

  createNewDevice: (
    newDevice: DeviceUpdate,
    model: DeviceModel,
  ): Promise<Device> => {
    return apiCall<CreatePostResponse>('/devices', 'POST', newDevice).then(
      (serverDevice) => {
        return device_helpers.unpack(serverDevice, model);
      },
    );
  },

  createServerDevice: (device: DeviceUpdate): Promise<ServerDevice> => {
    return apiCall<CreatePostResponse>('/devices', 'POST', device);
  },

  deleteDevice: (deviceId: string): Promise<void> => {
    return apiCall<any>('/devices/' + deviceId, 'DELETE');
  },

  fetchManufacturers: (): Promise<ManufacturersGetResponse> => {
    return apiCall<ManufacturersGetResponse>('/manufacturers', 'GET').then(
      (result) => {
        useManufacturers.getState().load([...result]);
        return result;
      },
    );
  },

  fetchModels: (): Promise<ModelsGetResponse> => {
    return apiCall<ModelsGetResponse>('/models', 'GET').then((result) => {
      useModels.getState().load([...result]);
      return result;
    });
  },

  fetchProjects: (): Promise<Project[]> => {
    return apiCall<Project[]>('/projects', 'GET', null);
  },

  createProject: (project: NewProject): Promise<Project> => {
    return apiCall<Project>('/projects', 'POST', project);
  },
  updateProject: (project: Project): Promise<Project> => {
    return apiCall<Project>('/projects', 'PUT', project);
  },
  removeProject: (id: number): Promise<void> => {
    return apiCall('/projects/' + id, 'DELETE');
  },

  fetchInsights: (): Promise<Insight[]> => {
    return apiCall<Insight[]>('/insights', 'GET', null);
  },

  createInsight: (newInsight: NewInsight): Promise<Insight> => {
    return apiCall<Insight>('/insights', 'POST', newInsight);
  },
  updateInsight: (insight: Insight): Promise<Insight> => {
    return apiCall<Insight>('/insights', 'PUT', insight);
  },
  removeInsight: (id: number): Promise<void> => {
    return apiCall('/insights/' + id, 'DELETE');
  },
  fetchRulesets: (): Promise<Ruleset[]> => {
    return apiCall<Ruleset[]>('/rules', 'GET');
  },
  fetchRulesetEvents: (): Promise<RulesetEvent[]> => {
    return apiCall<RulesetEvent[]>('/events', 'GET');
  },
  createRuleset: (ruleset: Ruleset): Promise<Ruleset> => {
    return apiCall<Ruleset>('/rules', 'POST', ruleset);
  },
  updateRuleset: (ruleset: Ruleset): Promise<Ruleset> => {
    return apiCall<Ruleset>('/rules', 'PUT', ruleset);
  },
  removeRuleset: (id: number): Promise<void> => {
    return apiCall('/rules/' + id, 'DELETE');
  },

  setShortUrl: (keyword: string, url: string): Promise<ShortURL> => {
    const URL = 'https://allb.in/urls/' + keyword;
    return apiCall<ShortURL>(URL, 'POST', { url });
  },

  updateShortUrl: (keyword: string, url: string): Promise<ShortURL> => {
    const URL = 'https://allb.in/urls/' + keyword;
    return apiCall<ShortURL>(URL, 'PUT', { url });
  },

  fetchGroups: (): Promise<Group[]> => {
    return apiCall<Group[]>('/groups', 'GET');
  },
  createGroup: (group: Group): Promise<Group> => {
    return apiCall<Group>('/groups', 'POST', group);
  },
  updateGroup: (group: Group): Promise<Group> => {
    return apiCall<Group>('/groups', 'PUT', group);
  },
  removeGroup: (id: number): Promise<void> => {
    return apiCall('/groups/' + id, 'DELETE');
  },

  fetchTransforms: (): Promise<Transform[]> =>
    apiCall<Transform[]>('/transforms', 'GET'),

  uploadImage: (url: string, data: string): Promise<string> => {
    const parts = encodedImageRegExp.exec(data);
    if (!parts || parts.length < 3) {
      return Promise.reject('failed to parse image data');
    }
    const dataBytes = Uint8Array.from(atob(parts[2]), (c) => c.charCodeAt(0));

    const req: AxiosRequestConfig = {
      url,
      method: 'POST',
      headers: {
        'Content-Type': parts[1],
      },
    };
    req.data = dataBytes;

    return axios(req).then((res) => {
      return `${url}/${res.data.id as string}`;
    });
  },

  connectWebsocket: (
    uri: string,
    eventHandler: (message: any) => void,
    setConnected: (connected: boolean) => void,
  ): WebSocket | null => {
    const connect = (): WebSocket | null => {
      try {
        if (ws !== null) {
          return ws;
        }
        // console.log('setting up websocket');
        ws = new WebSocket(uri);
        ws.onopen = () => {
          // console.log('connected');
          setConnected(true);
          const token = useSSOStore.getState().token;
          if (token) {
            websocketLogin(token.jwt);
          }
        };

        ws.onmessage = (e: MessageEvent) => {
          eventHandler(JSON.parse(e.data, entityReviver));
        };

        ws.onclose = () => {
          setConnected(false);
          ws = null;
          setTimeout(connect, 3000);
        };

        ws.onerror = () => {
          if (ws !== null) {
            ws.close();
          }
        };
      } catch (e) {
        setConnected(false);
        ws = null;
        setTimeout(connect, 3000);
      }
      return ws;
    };

    return connect();
  },
};

export default apiFuncs;
